import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import Button from 'react-bootstrap/lib/Button';
import { SaveInputsMixin } from '../mixins';
import Helmet from 'react-helmet';
import ResetActions from '../../actions/reset_actions';
import ResetStore from '../../stores/reset_store';
import { FormControl } from 'react-bootstrap';

const ForgotPassword = createReactClass({

  mixins: [
    SaveInputsMixin,
    Reflux.listenTo(ResetStore, 'onDataChange')
  ],

  getInitialState() {
    return {
      reset: false,
      passwordType: 'password',
      validation: {
        errors: {}
      }
    };
  },

  emailField: null,

  onDataChange(data) {
    this.setState(data);
  },

  renderBody() {
    return (
      <div className="container loginComponent">
        <Helmet title="Reset Password - Pollfish.com - Online Survey Platform" />
        <h3>Reset Password</h3>
        <form onSubmit={this.handleSubmit}>
           <FormControl
            type="text"
            placeholder="EMAIL ADDRESS"
            ref="email"
            inputRef={ref => { this.emailField = ref; }}
          />
          <label className="error">{this.state.validation.errors.email}</label>
          <div className="clearer">
            <Button className="btn-1c do-login" onClick={this.handleSubmit}>
              <span>Send Me Password Instructions</span>
              <i className="icon-arrow"></i>
            </Button>
          </div>
          <div className="clearer"></div>
          <div className="go-register">
            Don't have an account? <a href="/signup/researcher">Sign Up</a>
          </div>
        </form>
      </div>
    );
  },

  renderResetMessage() {
    return (
      <div className="container loginComponent">
        <i className="icon-email" />
        <p className="reset-message">Password reset instructions have been emailed to you, follow the link in the email to continue.</p>
      </div>
    );
  },

  render() {
    let content;
    if (this.state.reset) {
      content = this.renderResetMessage();
    } else {
      content = this.renderBody();
    }

    return content;
  },

  handleSubmit(ev) {
    ev.preventDefault();
    const data = {
      email: this.emailField.value
    };

    ResetActions.set(data);
    ResetActions.requestPassword();
  }

});

export default ForgotPassword;

