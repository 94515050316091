const sdks = [

  {
    title: 'Pollfish Android SDK - Google Play Edition',
    name: 'android/google-play',
    files: [
      { version: '6.5.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/Android/Pollfish%20Google%20Play%20Android%20SDK-6.5.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/android-sdk-pollfish/' }
    ]
  },

  {
    title: 'Pollfish Android SDK - Universal Edition',
    name: 'android/universal',
    files: [
      { version: '6.5.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/Android/Pollfish%20Universal%20Android%20SDK-6.5.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/android-sdk-pollfish/' }
    ]
  },

  {
    title: 'Pollfish Android SDK v7 (Beta)',
    name: 'android-v7',
    files: [
      { version: '7.0.0-beta07', url: 'https://storage.googleapis.com/pollfish_production/sdk/Android/Prodege%20Android%20SDK-7.0.0-beta07.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/android-sdk-pollfish/' }
    ]
  },

  {
    title: 'iOS SDK',
    name: 'ios',
    files: [
      { version: '6.5.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/iOS/Pollfish%20iOS%20SDK%206.5.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/ios-sdk-pollfish'  }
    ]
  },

  { title: 'WebPlugin SDK', name: 'webplugin', files: [] },

  { title: 'Rewarded Surveys', name: 'rewarded-surveys', files: [] },

  { title: 'Offerwall', name: 'offerwall', files: [] },

  { title: 'Pay on Screenouts', name: 'pay-on-screenouts', files: [] },

  { title: 'Demographic Surveys', name: 'demographic-surveys', files: [] },

  {
    title: 'Unity Plugin',
    name: 'unity',
    files: [
      { title: 'Download Unity Plugin', version: '7.1.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/Unity/Pollfish%20Unity%20Plugin%20v7.1.0.zip' }
    ]
  },

  { title: 'Flutter', name: 'flutter', files: [] },

  { title: 'React Native', name: 'react-native', files: [] },

  { title: 'AdMob Android Adapter', name: 'android-admob-adapter', files:
    [
      { title: 'Download Adapter', version: '7.0.0-beta07.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/AdMob/Prodege%20Android%20AdMob%20Adapter-7.0.0-beta07.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/android-admob-adapter' },
    ]
  },

  { title: 'AdMob iOS Mediation', name: 'ios-admob-adapter', files:
    [
      { title: 'Download Adapter', version: '6.4.2.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/AdMob/Pollfish%20AdMob%20Adapter%20iOS-6.4.2.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/ios-admob-adapter' },
    ]
  },

  { title: 'AppLovin Max Android Adapter', name: 'android-max-adapter', files:
    [
      { title: 'Download Adapter', version: '7.0.0-beta07.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/AppLovin/Prodege%20Android%20Max%20Adapter-7.0.0-beta07.0.zip' },
      { title: 'Sample Project', url: 'https://github.com/pollfish/android-max-adapter' }
    ]
  },

  { title: 'AppLovin Max iOS Adapter', name: 'ios-max-adapter', files:
        [
          { title: 'Download Adapter', version: '6.4.2.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/AppLovin/Pollfish%20Max%20Adapter%20iOS-6.4.2.0.zip' },
          { title: 'Sample Project', url: 'https://github.com/pollfish/ios-max-adapter' }
        ]
  },

  { title: 'IronSource Android Adapter', name: 'android-ironsource-adapter', files:
        [
          { title: 'Download Adapter', version: '7.0.0-beta07.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/IronSource/Prodege%20Android%20IronSource%20Adapter-7.0.0-beta07.0.zip' },
          { title: 'Sample Project', url: 'https://github.com/pollfish/android-ironsource-adapter' }
        ]
  },

  { title: 'IronSource iOS Adapter', name: 'ios-ironsource-adapter', files:
        [
          { title: 'Download Adapter', version: '6.4.2.0', url: 'https://storage.googleapis.com/pollfish_production/sdk/IronSource/Pollfish%20IronSource%20Adapter%20iOS-6.4.2.0.zip' },
          { title: 'Sample Project', url: 'https://github.com/pollfish/ios-ironsource-adapter' }
        ]
  },

  { title: 'Mediation Platform', name: 'mediation', files: [ ] },

  { title: 'API Documentation', name: 'api-documentation', files: [ ] },

  { title: 'Dashboard API', name: 'dashboard-api', files: [ ] },

  { title: 'Server-to-Server Callbacks', name: 's2s', files: [  ] }

];

export default sdks;

