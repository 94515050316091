import { findIndex, groupBy } from 'lodash';
import { CATEGORIES_MAP, OVERRIDE_MAP } from './constants';

export const isDestinationInCategory = (category, destination) => {
  const isInOverriddenCategory = Boolean(OVERRIDE_MAP[destination.id]);
  if (isInOverriddenCategory) {
    return OVERRIDE_MAP[destination.id] === category.name;
  }
  return category.categories.includes(destination.category);
};

export const getCategoryByDestinations =  (destinations) => groupBy(destinations, (destination) => {
  const index = findIndex(CATEGORIES_MAP, (categoryGroup) => isDestinationInCategory(categoryGroup, destination));
  let result;
  if(index !== -1) {
    result = CATEGORIES_MAP[index].name;
  } else {
    result = CATEGORIES_MAP[2].name; //Essential Cookies
  }

  return result;
});
