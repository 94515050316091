import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import { FormControl } from 'react-bootstrap';
import Button from 'react-bootstrap/lib/Button';
import notify from 'notify';
import SinglePage from 'single_page';
import request from 'superagent';
import Helmet from 'react-helmet';

const OptOut = createReactClass({
  getMetaProps() {
    return {
      title: 'Opt Out · Pollfish',
      link: [
        { rel: 'canonical', href: 'https://www.pollfish.com/respondent/opt-out' }
      ],
      meta: [
        { name: 'description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' },
        { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
        { property: 'og:title', content: 'Opt Out · Pollfish' },
        { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
        { property: 'og:description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' }
      ]
    };
  },

  uniqueId: null,

  renderContent() {
    return (
      <div className="opt-out">
        <Helmet {...this.getMetaProps()} />
        <h2 className="static-page-title">Opt Out</h2>
        <p>
          You can at any time request to stop receiving  or opt-out from Pollfish surveys from within your apps or website, (as described in our <a href="https://www.pollfish.com/terms/respondent">Respondent Terms of Use & Privacy Policy</a> by following the instructions below:
        </p>
        <h3>Android</h3>
        <p>
          In order to disable surveys from within your app, please visit your Google Settings on your phone in the Ads section and copy and submit your Advertising Identifier in the field below.
        </p>
        <h3>iOS</h3>
        <p>
          In order to disable surveys from within your app, please visit your Privacy section in Settings and then choose Advertising. After that you can enable Limit Ad Tracking. If you are able to retrieve your Advertising identifier you can also submit it in the field below in order to opt out.
        </p>
        <h3>Web</h3>
        <p>
          You can disable surveys from your browser by submitting in the filed below a unique number found in Pollfish cookie placed by the web plugin. In order to find the cookie stored by Pollfish web plugin you need to open the cookie section within your browser and filter cookies with the domain name of the website that hosts Pollfish web plugin. Then once you see the list with all the cookies from that website you need to copy the value of the cookie "uuid" and paste it in the field below.
        </p>
        <hr />
        <form ref="form">
          <FormControl
            type="text"
            placeholder="Enter your unique ID here"
            inputRef={ref => { this.uniqueId = ref; }}
          />
        </form>
        <Button onClick={ this.handleClick } >
          <span>Submit</span>
          <i className="icon-arrow"></i>
        </Button>
        <hr />
        <h3 style={{ fontWeight: 'bolder', marginBottom: 15 }}>Reset</h3>
        <p>
          You can at any time erase any survey related profile created by Pollfish platform around your mobile device or browser by following the instructions below:
        </p>
        <h3>Android</h3>
        <p>
          Visit your Google Settings and then in Ads section choose to reset your Advertising Identifier.
        </p>
        <h3>iOS</h3>
        <p>
          Visit your Privacy section in your Settings and then choose Advertising. After that, you can reset your Advertising Identifier.
        </p>
        <h3>Web</h3>
        <p>
          Open your cookies section in your browser and delete Pollfish cookie.
        </p>
        <hr />
        <p>
          By following the aforementioned procedures, you mobile device or browser will consider you as a completely new user for Pollfish platform and will ignore previous survey related profiles.
        </p>
      </div>
    );
  },

  render() {
    return (
      <SinglePage
        title="Opt Out"
        content={ this.renderContent() } />
    );
  },

  handleClick(ev) {
    ev.preventDefault();

    const uniqueId = this.uniqueId.value;

    if (uniqueId.length) {
      request
        .post('/v2/device/optout')
        .query({ uniqueId })
        .end((err) => {
          if (err && !_.isUndefined(window.Raven)) {
            Raven.captureException(err, { extra: { where: 'optOut' } });
          }
          ReactDOM.findDOMNode(this.refs.form).reset();
          notify({
            message: 'Your request has been received',
            level: 'success'
          });
        });
    } else {
      notify({
        message: 'The field is empty. Please try again.',
        level: 'error'
      });
    }
  }

});

export default OptOut;

