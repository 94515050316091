import React from 'react';
import createReactClass from 'create-react-class';
import Button from 'react-bootstrap/lib/Button';
import SinglePage from 'single_page';
import Helmet from 'react-helmet';

const Demo = createReactClass({

  getMetaProps() {
    return {
      title: 'Demo of Pollfish surveys on Mobile Apps and Website - Pollfish',
      link: [
        { rel: 'canonical', href: 'https://www.pollfish.com/publisher/demo' }
      ],
      meta: [
        { name: 'description', content: 'Pollfish is a mobile survey and data collection app. You can experience Pollfish surveys through our android app, ios app and website plugin.' },
        { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
        { property: 'og:title', content: 'Demo of Pollfish surveys on Mobile Apps and Website - Pollfish' },
        { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
        { property: 'og:description', content: 'Pollfish is a mobile survey and data collection app. You can experience Pollfish surveys through our android app, ios app and website plugin.' }
      ]
    };
  },

  renderContent() {
    return (
      <div>
        <Helmet {...this.getMetaProps()} />
        <h1 className="static-page-title">Demo Apps</h1>
        <p>You can experience Pollfish surveys through our:</p>
        <div className="demo-apps">
          <Button className="btn-1c demo-button demo-block" href="https://play.google.com/store/apps/details?id=com.pollfish.demo" target="_blank">
            <i className="icon-android type"></i>
            <span>Android Demo</span>
            <i className="icon-arrow"></i>
          </Button>
          <Button className="btn-1c demo-button demo-block" href="https://itunes.apple.com/us/app/pollfish-chalkboard/id782617904" target="_blank">
            <i className="icon-apple type"></i>
            <span><span style={ { textTransform: 'lowercase' } }>i</span>OS Demo</span>
            <i className="icon-arrow"></i>
          </Button>
          <Button className="btn-1c demo-button demo-block" href="/webplugin" target="_blank">
            <i className="icon-web type"></i>
            <span>Webplugin Demo</span>
            <i className="icon-arrow"></i>
          </Button>
          <Button style={{marginBottom: '14px'}} className="btn-1c demo-button demo-block" href="/webplugin/mobiledemo" target="_blank">
            <i className="icon-web type"></i>
            <span>Mobile Demo</span>
            <i className="icon-arrow"></i>
          </Button>
        </div>
      </div>

    );
  },

  render() {
    return (
      <SinglePage
        title="Demo"
        content={ this.renderContent() } />
    );
  }

});

export default Demo;

