import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Carousel from 'react-bootstrap/lib/Carousel';
import CarouselItem from 'react-bootstrap/lib/CarouselItem';
import Footer from '../common/footer';
import { MountResponsiveMixin } from '../mixins';
import ReactResponsiveMixin from 'react-responsive-mixin';

const Testimonials = createReactClass({
  propTypes: {
    className: PropTypes.string,
    description: PropTypes.node,
    iconFont: PropTypes.bool,
    logos: PropTypes.array.isRequired
  },

  contextTypes: {
    screen: PropTypes.string.isRequired
  },

  mixins: [ ReactResponsiveMixin, MountResponsiveMixin ],

  renderLogos() {
    let logos = [];
    if (this.props.logos && this.props.iconFont) {
      logos = this.props.logos.map((logo, index) => {
        const key = `thumbnails-${index}`;
        const className = `icon-${logo}`;
        let icons;
        if (this.context.screen === 'desktop') {
          icons = (
              <i key={key} className={className}></i>
          );
        } else {
          icons = (
            <CarouselItem key={key}>
              <i className={className}></i>
            </CarouselItem>
          );
        }

        return icons;
      });
    } else {
      logos = this.props.logos.map((logo, index) => {
        const key = `thumbnails-${index}`;
        return (
          <CarouselItem
            key={key}
            className="trusted-by-publishers__item"
          >
            <div className={`trusted-by-publishers__img ${logo}`}></div>
          </CarouselItem>);
      });
    }

    return logos;
  },

  renderTestimonials() {
    let Wrap;

    if (this.context.screen === 'mobile' || this.context.screen === 'tablet') {
      Wrap = Carousel;
    } else {
      Wrap = Row;
    }

    let logosRender;

    logosRender = (
      <Wrap className="trusted-by-publishers__list">
        {this.renderLogos()}
      </Wrap>
    );

    let cont;

    if (this.context.screen === 'large' || this.context.screen === 'desktop') {
      cont = (
        <Grid fluid>
          <div className="desc">
            { this.props.description }
          </div>
          { logosRender }
          <Footer />
        </Grid>
      );
    } else {
      cont = (
        <Grid>
          <div className="desc">
            { this.props.description }
          </div>
          { logosRender }
          <Footer />
        </Grid>
      );
    }

    return cont;
  },

  render() {
    return (
      <div className={this.props.className}>
        { this.renderTestimonials() }
      </div>
    );
  }
});

export default Testimonials;

