import Reflux from 'reflux';

const LoginActions = Reflux.createActions([
  'login',
  'googleLogin',
  'set'
]);

export default LoginActions;

