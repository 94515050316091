import { merge } from 'lodash';
import React, { Fragment } from 'react';
import Reflux from 'reflux';
import SinglePage from '../common/single_page';
import Markdown from 'react-remarkable';
import capitalize from 'capitalize';
import TermsStore from '../../stores/terms_store';
import TermsActions from '../../actions/terms_actions';
import Loader from '../common/loader';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import CookieConsent from './cookie-consent';

class Terms extends Reflux.Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState();
    this.store = TermsStore;
    this.consentRef = React.createRef()
  }

  componentDidMount() {
    TermsActions.fetch(this.props.match.params.type);

    // this is called from markdown cookies-policy page, onClick
    window.openConsent = (e) => {
      e.preventDefault();
      this.consentRef.current.showModal();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    TermsActions.fetch(nextProps.match.params.type);
  }

  componentWillUnmount() {
    window.openConsent = null;
  }

  onDataChange(data) {
    merge(data, { loading: false });
    this.setState(data);
  }

  getInitialState() {
    return {
      content: '',
      loading: true,
    };
  }

  getMeta() {
    const type = capitalize(this.props.match.params.type);

    return {
      researcher: {
        title: 'Researcher\'s Terms of Use and Privacy Policy - Pollfish',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/terms/${this.props.match.params.type}`}
        ],
        meta: [
          { name: 'description', content: 'Browse through the researcher terms of service agreement and privacy policy at Pollfish, the online survey platform.' },
          { name: 'keywords', content: 'online survey tools, survey tools, online survey software, survey, mobile, smartphone, fast, accurate, poll, market research' },
          { property: 'og:title', content: 'Researcher\'s Terms of Use and Privacy Policy - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Browse through the researcher terms of service agreement and privacy policy at Pollfish, the online survey platform.' }
        ]
      },
      publisher: {
        title: 'Publisher\'s Terms of Use and Privacy Policy - Pollfish',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/terms/${this.props.match.params.type}`}
        ],
        meta: [
          { name: 'description', content: 'Browse through the publisher terms of service agreement and privacy policy at Pollfish, the online survey platform.' },
          { name: 'keywords', content: 'online survey tools, survey tools, online survey software, survey, mobile, smartphone, fast, accurate, poll, market research' },
          { property: 'og:title', content: 'Publisher\'s Terms of Use and Privacy Policy - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Browse through the publisher terms of service agreement and privacy policy at Pollfish, the online survey platform.' }
        ]
      },
      other: {
        title: `${type}'s Terms of Use - Pollfish`,
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/terms/${this.props.match.params.type}`}
        ],
        meta: [
          { name: 'description', content: 'Pollfish\'s online survey tool delivers quick survey results globally through millions of survey repondents who take your survey. This software ensures cost-effective and quick survey results.' },
          { name: 'keywords', content: 'Online Survey Tool, Fast & Accurate Market Research - Pollfish.com' },
          { property: 'og:title', content: 'Researcher\'s Terms of Use and Privacy Policy - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Pollfish\'s online survey tool delivers quick survey results globally through millions of survey repondents who take your survey. This software ensures cost-effective and quick survey results.' }
        ]
      }
    };
  }

  getMetaProps() {
    let props;
    const type = this.props.match.params.type;
    const meta = this.getMeta();

    if (type === 'researcher' || type === 'publisher') {
      props = meta[type];
    } else {
      props = meta.other;
    }

    return props;
  }

  renderContent() {
    let content;
    if (this.state.loading) {
      content = <Loader />;
    } else {
      content = <Markdown options={ { html: true } } source={this.state.content} />;
    }

    return content;
  }

  render() {
    return (
      <Fragment>
        <Helmet {...this.getMetaProps()} />
        <SinglePage
          title="Terms of Service"
          content={ this.renderContent() }
        />
        <IntlProvider locale="en">
          <CookieConsent ref={ this.consentRef } />
        </IntlProvider>
      </Fragment>
    );
  }
}

export default Terms;

