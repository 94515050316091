import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';
import Grid from 'react-bootstrap/lib/Grid';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavbarBrand from 'react-bootstrap/lib/NavbarBrand';

import Notification from './notification';

const Layout = createReactClass({

  propTypes: {
    children: PropTypes.array,
    location: PropTypes.object,
    match: PropTypes.object
  },

  childContextTypes: {
    location: PropTypes.object,
    match: PropTypes.object
  },

  getChildContext() {
    return {
      location: this.props.location,
      match: this.props.match
    };
  },

  render() {
    return (
      <Grid id="auth" className="full" fluid>
        <TransitionGroup>
          <CSSTransition
            key="auth"
            classNames="page"
            timeout={1000}
            exit={false}
            appear
          >
            <div className="page">
              <Navbar>
                <NavbarBrand>
                  <a href="/">
                    <i className="icon-logo"></i>
                    <i className="icon-pollfish"></i>
                  </a>
                </NavbarBrand>
              </Navbar>
              { this.props.children }
              <Notification />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Grid>
    );
  }
});

export default withRouter(Layout);

