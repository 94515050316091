"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.deferWith = exports.triggerAsync = exports.trigger = exports.listen = exports.shouldEmit = exports.preEmit = undefined;

var _utils = require("./utils");

var _ = _interopRequireWildcard(_utils);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

/**
 * A module of methods for object that you want to be able to listen to.
 * This module is consumed by `createStore` and `createAction`
 */

/**
 * Hook used by the publisher that is invoked before emitting
 * and before `shouldEmit`. The arguments are the ones that the action
 * is invoked with. If this function returns something other than
 * undefined, that will be passed on as arguments for shouldEmit and
 * emission.
 */
var preEmit = exports.preEmit = function preEmit() {};

/**
 * Hook used by the publisher after `preEmit` to determine if the
 * event should be emitted with given arguments. This may be overridden
 * in your application, default implementation always returns true.
 *
 * @returns {Boolean} true if event should be emitted
 */
var shouldEmit = exports.shouldEmit = function shouldEmit() {
    return true;
};

/**
 * Subscribes the given callback for action triggered
 *
 * @param {Function} callback The callback to register as event handler
 * @param {Mixed} [optional] bindContext The context to bind the callback with
 * @returns {Function} Callback that unsubscribes the registered event handler
 */
var listen = exports.listen = function listen(callback, bindContext) {
    bindContext = bindContext || this;
    var eventHandler = function eventHandler(args) {
        if (aborted) {
            return;
        }
        callback.apply(bindContext, args);
    },
        me = this,
        aborted = false;
    this.emitter.addListener(this.eventLabel, eventHandler);
    return function () {
        aborted = true;
        me.emitter.removeListener(me.eventLabel, eventHandler);
    };
};

/**
 * Publishes an event using `this.emitter` (if `shouldEmit` agrees)
 */
var trigger = exports.trigger = function trigger() {
    var args = arguments,
        pre = this.preEmit.apply(this, args);
    args = pre === undefined ? args : _.isArguments(pre) ? pre : [].concat(pre);
    if (this.shouldEmit.apply(this, args)) {
        this.emitter.emit(this.eventLabel, args);
    }
};

/**
 * Tries to publish the event on the next tick
 */
var triggerAsync = exports.triggerAsync = function triggerAsync() {
    var args = arguments,
        me = this;
    _.nextTick(function () {
        me.trigger.apply(me, args);
    });
};

/**
 * Wraps the trigger mechanism with a deferral function.
 *
 * @param {Function} callback the deferral function,
 *        first argument is the resolving function and the
 *        rest are the arguments provided from the previous
 *        trigger invocation
 */
var deferWith = exports.deferWith = function deferWith(callback) {
    var oldTrigger = this.trigger,
        ctx = this,
        resolver = function resolver() {
        oldTrigger.apply(ctx, arguments);
    };
    this.trigger = function () {
        callback.apply(ctx, [resolver].concat([].splice.call(arguments, 0)));
    };
};