import _ from 'lodash';
import radio from 'radio';

export const OnePageMixin = {

  beforeMove(nextIndex, previousIndex) {
    if (nextIndex === 1 || nextIndex === 2 || previousIndex === 3) {
      radio('websocket:start').broadcast();
    }
    if (nextIndex === 3 || previousIndex === 2) {
      radio('websocket:pause').broadcast();
    }

    radio('update:header').broadcast(this.sections[nextIndex]);
  },

  afterMove(index) {
    radio('video:play').broadcast(index);
  }

};

export const MountResponsiveMixin = {

  UNSAFE_componentWillMount() {
    const self = this;

    this.media({maxWidth: 600}, {
      deferSetup: true,
      match: () => {
        self.setState({ screen: 'mobile' });
      }
    });

    this.media('(min-aspect-ratio: 14/9)', {
      deferSetup: true,
      match: () => {
        self.setState({ orientation: 'landscape' });
      }
    });

    this.media('(max-aspect-ratio: 14/9)', {
      deferSetup: true,
      match: () => {
        self.setState({ orientation: 'portrait' });
      }
    });

    this.media({minWidth: 768, maxWidth: 1024}, {
      deferSetup: true,
      match: () => {
        self.setState({ screen: 'tablet' });
      }
    });

    this.media({minWidth: 1025}, {
      deferSetup: true,
      match: () => {
        self.setState({ screen: 'desktop' });
      }
    });

    this.media({minWidth: 1450}, {
      deferSetup: true,
      match: () => {
        self.setState({ screen: 'large' });
      }
    });
  }

};

export const SaveInputsMixin = {

  UNSAFE_componentWillMount() {
    this.serializeData = _.throttle(_.bind(this.serializeData), 300);
  },

  serializeData() {
    const params = {};

    _.each(_.omit(this.refs, ['modalLogin', 'modalSignup', 'modalReset', 'gsignin']), (ref, key) => {
      const value = ref.getValue();
      if (value || value === '') {
        params[key] = ref.getValue();
      }
    });

    this.saveInputs(params);
  },

  handleChange(ev) {
    ev.preventDefault();
    if (this.onHandleChange) {
      this.onHandleChange();
    }
    this.serializeData();
  }

};

