import React from 'react';
import ReactDOM from 'react-dom';
import getHistory from './util/app_history';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import Layout from './components/common/layout';
import AuthLayout from './components/common/auth_layout';
import Docs from './components/docs';
import Terms from './components/terms';
import ForgotPassword from './components/common/forgot_password';
import ResetPassword from './components/common/reset_password';
import Demo from './components/demo';
import MediaKit from './components/company/media_kit';
import Referral from './components/publisher/referral';
import OptOut from './components/respondent/opt_out';
import Testimonials from './components/publisher/testimonials';

const start = () => {
  const history = getHistory();

  try {
    window.analytics.page();
  } catch (e) {
    // do nothing with the error here
  }
  ReactDOM.render((
    <Router history={history}>
      <Switch>
        <Route path={['/forgotpassword', '/misc/reset']}>
          <AuthLayout>
            <Route path="/misc/reset" component={ResetPassword} />
            <Route path="/forgotpassword" component={ForgotPassword} />
          </AuthLayout>
        </Route>
        <Route path={[
          '/misc/forgot', '/docs', '/company/mediakit', '/referral',
          '/publisher/demo', '/publisher/testimonials', '/publisher/referral',
          '/respondent/opt-out', '/terms/:type',
          ]}>
          <Layout>
            <Switch>
              <Route path="/misc/forgot" component={ForgotPassword} />

              <Redirect exact from="/docs" to="/docs/android/google-play" />
              <Redirect exact from="/docs/android" to="/docs/android/google-play" />
              <Redirect from="/docs/basic4android-universal" to="/docs/basic4android" />
              <Redirect from="/docs/basic4android-google-play" to="/docs/basic4android" />
              <Route path="/docs/:platform/:type" component={Docs} />
              <Route path="/docs/:platform" component={Docs} />

              <Route path="/company/mediakit" component={MediaKit} />

              <Route path="/respondent/opt-out" component={OptOut} />

              <Redirect from="/referral" to="/publisher/referral" />
              <Route path="/publisher/demo" component={Demo} />
              <Route path="/publisher/testimonials" component={Testimonials} />
              <Route path="/publisher/referral" component={Referral} />
              <Redirect from="/terms/developer" to="/terms/publisher" />
              <Route path="/terms/:type" component={Terms} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </Router>),
    document.getElementById('content')
  );
};

export default { start };

