import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Sidebar from './sidebar';
import Customers from '../home/customers';
import radio from 'radio';
import cx from 'classnames';
import menuLinks from '../../util/menu_links';

const SinglePage = createReactClass({

  propTypes: {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired
  },

  contextTypes: {
    screen: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired
  },

  UNSAFE_componentWillMount() {
    radio('update:header').broadcast({ title: this.props.title });
  },

  getSidebarClasses() {
    return cx({
      'sidebar-static': true,
      'mobile': (this.context.screen === 'mobile')
    });
  },

  renderSidebar() {
    let columns;

    if (this.context.screen !== 'mobile') {
      columns = 4;
    } else {
      columns = 12;
    }

    return (
      <Col xs={columns} md={columns} className={this.getSidebarClasses()}>
        <Sidebar items={menuLinks()} />
      </Col>
    );
  },

  renderContent() {
    let content;

    if (this.context.screen !== 'mobile') {
      content = (
        <Col id="document" xs={8} md={8}>
          { this.props.content }
        </Col>
      );
    } else {
      content = (
        <Col id="document" xs={12} md={12}>
          <div className="single-page-wrapper">
            { this.props.content }
          </div>
        </Col>
      );
    }

    return content;
  },

  renderOnePage() {
    let cont;
    if (this.context.screen === 'large') {
      cont = (
        <Grid fluid className="one-page-static">
          <Row>
            { this.renderSidebar() }
            { this.renderContent() }
          </Row>
        </Grid>
      );
    } else {
      cont = (
        <Grid>
          <Row>
            { this.renderSidebar() }
            { this.renderContent() }
          </Row>
        </Grid>
      );
    }

    return cont;
  },

  render() {
    return (
      <div>
        { this.renderOnePage() }
        <Customers className="footer-single-page" />
      </div>
    );
  }
});

export default SinglePage;
