import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import Colophon from '../common/colophon';
import menuLinks from '../../util/menu_links';
import Sidebar from './sidebar';

const Footer = createReactClass({

  contextTypes: {
    screen: PropTypes.string.isRequired
  },

  renderMenu() {
    let menu;
    if (this.context.screen !== 'mobile') {
      menu = (
        <div>
          <Row className="footer-titles">
            <Col xs={3} md={3} lg={3}>
              About
            </Col>
            <Col xs={3} md={3} lg={3}>
              Researcher
            </Col>
            <Col xs={3} md={3} lg={3}>
              Publisher
            </Col>
            <Col xs={3} md={3} lg={3}>
              Respondent
            </Col>
          </Row>
          <Row>
            <Col xs={3} md={3} lg={3}>
              <ul>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/team">Company</a></li>
                <li><a href="https://www.pollfish.com/pollfish-and-prodege/" target="_blank">Pollfish & Prodege</a></li>
                <li><a href="https://prodege.hrmdirect.com/employment/job-openings.php?search=true&city=Athina&state=-1&office=-1" target="_blank">Careers</a></li>
                <li><a href="http://www.pollfish.com/blog" target="_blank">Blog</a></li>
                <li><Link to="/company/mediakit">Media Kit</Link></li>
              </ul>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <ul>
                <li><a href="https://help.pollfish.com/faq-researchers" target="_blank">FAQ</a></li>
                <li><a href="https://resources.pollfish.com/case-studies/" target="_blank">Case studies</a></li>
              </ul>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <ul>
                <li><Link to="/docs/android/google-play">Android documentation</Link></li>
                <li><Link to="/docs/android-v7">Android v7 (Beta) documentation</Link></li>
                <li><Link to="/docs/ios">iOS documentation</Link></li>
                <li><Link to="/docs/webplugin">Web Plugin documentation</Link></li>
                <li><Link to="/docs/unity">Other Plugins</Link></li>
                <li><Link to="/docs/s2s">Server-to-Server Callbacks</Link></li>
                <li><Link to="/docs/rewarded-surveys">Rewarded Surveys</Link></li>
                <li><Link to="/docs/offerwall">Offerwall</Link></li>
                <li><Link to="/docs/pay-on-screenouts">Pay on Screenouts</Link></li>
                <li><Link to="/docs/demographic-surveys">Demographic Surveys</Link></li>
                <li><Link to="/docs/mediation">Mediation Platform</Link></li>
                <li><Link to="/docs/api-documentation">API Documentation</Link></li>
                <li><Link to="/docs/dashboard-api">Dashboard API</Link></li>
                <li><Link to="/publisher/referral">Referral</Link></li>
                <li><a href="https://pollfish.com/case-studies/" target="_blank">Case studies</a></li>
                <li><Link to="/publisher/demo">Demo Apps</Link></li>
                <li><a href="https://help.pollfish.com/faq-publishers" target="_blank">FAQ</a></li>
              </ul>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <ul>
                <li><Link to="/respondent/opt-out">Opt out</Link></li>
              </ul>
            </Col>
          </Row>
        </div>
      );
    } else {
      menu = (
        <div className="footerMenu sidebar-static">
          <Sidebar items={ menuLinks() } isExpanded full />
        </div>
      );
    }

    return menu;
  },

  render() {
    return (
      <div className="footer">
        { this.renderMenu() }
        <Colophon />
      </div>
    );
  }
});

export default Footer;


