export default {
  email: {
    format: 'email',
    required: true,
    messages: {
      required: 'Email is required'
    }
  },
  password: {
    required: true,
    messages: {
      required: 'Password is required'
    }
  }
};

