import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import NotificationSystem from 'react-notification-system';
import radio from 'radio';

const Notification = createReactClass({
  componentDidMount() {
    this.filter = [];
    this._notificationSystem = this.refs.notificationSystem;
    radio('notification:create').subscribe(this._addNotification);
  },

  render() {
    return (
      <NotificationSystem ref="notificationSystem" />
    );
  },

  _notificationSystem: null,

  _addNotification(notification) {
    notification.position = 'tc';

    if (!~_.indexOf(this.filter, notification.message)) {
      this.filter.push(notification.message);
    } else {
      return false;
    }

    const self = this;
    notification.onRemove = (data) => {
      self.filter = _.without(self.filter, data.message);
    };

    this._notificationSystem.addNotification(notification);
  }

});

export default Notification;

