//Reference for @segment/consent-manager saveConsent function payload
// export const PREFS_DEFAULT = {
//   customPreferences: {
//     advertising: true,
//     functional: true,
//     marketingAndAnalytics: true
//   },
//   destinationPreferences: {
//     "AdWords": true,
//     "Customer.io": true,
//     "Facebook Pixel": true,
//     "Google Analytics": true,
//     "Google Tag Manager": true,
//     "HubSpot": true,
//     "Intercom": true,
//     "LinkedIn Insight Tag": true,
//     "PERSONAS - Google AdWords": true,
//     "Quora Conversion Pixel": true
//   }
// };

export const CATEGORIES_DICTIONARY = {
  marketingAndAnalytics: 'Marketing & Analytics',
  functional: 'Functional',
  advertising: 'Advertising',
};

export const CATEGORIES_MAP = [
  {
    categories: [
      'A/B Testing',
      'Analytics',
      'Attribution',
      'Email',
      'Email Marketing',
      'Enrichment',
      'Heatmaps & Recordings',
      'Raw Data',
      'Realtime Dashboards',
      'Referrals',
      'Surveys',
      'Video'
    ],
    name: 'marketingAndAnalytics',
  },
  {
    categories: ['Advertising', 'Tag Managers'],
    name: 'advertising',
  },
  {
    categories: [
      'CRM',
      'Customer Success',
      'Deep Linking',
      'Helpdesk',
      'Livechat',
      'Performance Monitoring',
      'Personalization',
      'SMS & Push Notifications',
      'Security & Fraud'
    ],
    name: 'functional',
  }
];

export const OVERRIDE_MAP = {
  HubSpot: 'functional', //matching with CATEGORIES_MAP.name
  'Customer.io': 'functional', //matching with CATEGORIES_MAP.name
};
