import { Entity } from 'relapse';
import loginValidations from './validations/login_validations.js';

class LoginForm extends Entity {
  constructor() {
    super(undefined, {
      url: '/api/v2/user/login',
      validations: loginValidations
    });
  }

  parse(data) {
    this.store = data;
    if (data.email) {
      data.loggedIn = true;
      data.isDeveloper = data.is_developer || data.isDeveloper || null;
      this.emit('login:success', data);
    }
  }
}

export default LoginForm;


