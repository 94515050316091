import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Testimonials from '../common/testimonials';

const logoBaseClass = 'trusted-by-publishers__img--';

const logos = [
  `${logoBaseClass}1`,
  `${logoBaseClass}2`,
  `${logoBaseClass}3`,
  `${logoBaseClass}4`,
  `${logoBaseClass}5`,
  `${logoBaseClass}6`,
  `${logoBaseClass}7`,
  `${logoBaseClass}8`,
  `${logoBaseClass}9`,
  `${logoBaseClass}10`,
  `${logoBaseClass}11`,
  `${logoBaseClass}12`,
  `${logoBaseClass}13`,
];

const Partners = createReactClass({

  propTypes: {
    className: PropTypes.string
  },

  getClassNames() {
    return cx(this.props.className, {
      'section-inner': true,
      'partners': true
    });
  },


  render() {
    return (
      <Testimonials
        className={this.getClassNames()}
        description="We are proud of the professional experience that we have gained over the years by working for small and high-profile clients. Our experience expands to some of the most beloved and well-known brands on the planet."
        logos={ logos } />
    );
  }
});

export default Partners;

