import _ from 'lodash';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import getHistory from 'app_history';
import ResetForm from '../entities/reset';
import ResetActions from '../actions/reset_actions';
import request from 'superagent';
import notify from 'notify';
import Raven from 'raven-js';

const ResetStore = Reflux.createStore({
  listenables: [ResetActions],

  contextTypes: {
    router: PropTypes.func.isRequired
  },

  init() {
    this.resetForm = new ResetForm();
    this.resetForm.on('data:change', this.updateData);
    this.resetForm.on('reset:success', this.updateData);
    this.resetForm.on('validation:done', this.validation);
    this.history = getHistory();
  },

  onSet(data) {
    this.resetForm.set(data);
  },

  onRequestPassword() {
    if (this.resetForm.validate().valid) {
      const email = this.resetForm.get('email');
      request
        .post('/api/v2/user/password/request/new')
        .query({ email })
        .end((err, res) => {
          if (res.statusCode === 400) {
            notify({
              message: 'The email address you entered is not registered.',
              level: 'error'
            });
            return false;
          }
          if (err && !_.isUndefined(window.Raven)) {
            Raven.captureException(err, { extra: { where: 'requestPassword' } });
            return false;
          }

          if (res.body.success) {
            this.trigger({
              reset: true,
              status: res.body.success
            });
          } else {
            this.trigger(res.body);
          }
        });
    }
  },

  onResetPassword(data) {
    request
      .post('/api/v2/user/password/set/new')
      .query(data)
      .end((err, res) => {
        if (!res.body.success) {
          _.each(res.body, (message, level) => {
            notify({ message, level });
          });
        }

        if (err && !_.isUndefined(window.Raven)) {
          Raven.captureException(err, { extra: { where: 'resetPassword' } });
          return false;
        }

        notify({
          message: 'Your password was successfully updated.',
          level: 'success'
        });

        setTimeout(() => {
          window.location = '/login';
        }, 2500);

        this.resetForm.set({ reset: true });
      });
  },

  updateData() {
    this.trigger(this.resetForm.toJSON());
  },

  validation(validation) {
    const errors = {};

    _.each(validation.errors, function(error) {
      errors[error.property] = error.message;
    });

    this.trigger({validation: {errors}});
  }

});

export default ResetStore;


