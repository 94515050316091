import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';

const socialWebsites = {
  facebook: 'https://www.facebook.com/pollfish',
  twitter: 'https://www.twitter.com/pollfish',
  linkedin: 'https://www.linkedin.com/company/pollfish'
};

const SocialButton = (props) => {
  const className = `icon-${props.type}`;

  return (
    <Button className="btn-1c" target="_blank" href={ props.href || socialWebsites[props.type] }>
      <i className={className}></i>
    </Button>
  );
};

SocialButton.propTypes =  {
  href: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default SocialButton;

