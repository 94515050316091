import { debounce, merge } from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import Button from 'react-bootstrap/lib/Button';
import URLSearchParams from 'url-search-params';
import InputPassword from '../password-field';
import ResetStore from '../../stores/reset_store';
import ResetActions from 'reset_actions';
import Helmet from 'react-helmet';
import notify from 'notify';
import urlParamsToObject from '../../util/urlParamsToObject';

const ResetPassword = createReactClass({

  contextTypes: {
    location: PropTypes.object.isRequired
  },

  mixins: [ Reflux.listenTo(ResetStore, 'onDataChange') ],

  UNSAFE_componentWillMount() {
    this.invalidURL = debounce(this.invalidURL, 700);
  },

  componentDidMount() {
    const params = new URLSearchParams(this.context.location.search);
    const email = params.get('email');
    const token = params.get('token');

    if (!(email && token)) {
      this.invalidURL();
    }
  },

  onDataChange(data) {
    if (data.reset) {
      this.refs.password.setState({ value: '' });
    }
  },

  render() {
    return (
      <div className="container loginComponent">
        <Helmet title="Enter your new Password - Pollfish.com - Online Survey Platform" />
        <h3>Enter your new password</h3>
        <form>
          <InputPassword
            ref="password"
            unMaskTime={5}
          />
          <div className="clearer">
            <Button type="submit" className="btn-1c do-login" bsSize="large" block onClick={this.handleChange}>
              <span>Reset My Password</span>
              <i className="icon-arrow"></i>
            </Button>
          </div>
        </form>
      </div>
    );
  },

  invalidURL() {
    notify({
      message: 'Your link is not valid. Please visit the link in the email we sent you, or request a new password reset.',
      level: 'error'
    });
  },

  handleChange(ev) {
    ev.preventDefault();

    const params = new URLSearchParams(this.context.location.search);
    const email = params.get('email');
    const token = params.get('token');

    if (email && token) {
      const pwd = this.refs.password.state.value;
      const data = urlParamsToObject(params);

      merge(data, { pwd });

      ResetActions.resetPassword(data);
    } else {
      this.invalidURL();
    }
  }

});

export default ResetPassword;
