import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import Sidebar from '../common/sidebar';
import Helmet from 'react-helmet';
import Partners from '../publisher/partners';
import Loader from '../common/loader';
import Button from 'react-bootstrap/lib/Button';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Markdown from 'react-remarkable';
import DocsStore from '../../stores/docs_store';
import DocsActions from '../../actions/docs_actions';
import sdks from './sdks';
import highlight from 'highlight.js';
import radio from 'radio';
import menuLinks from '../../util/menu_links';

const sidebar = [{
  name: 'Select SDK',
  routes: [
    {
      name: 'Android',
      eventKey: 1,
      routes: [
        { name: 'Google Play Edition', route: '/docs/android/google-play' },
        { name: 'Universal Edition', route: '/docs/android/universal' }
      ]
    },
    {
      name: 'Android v7 (Beta)', route: '/docs/android-v7'
    },
    {
      name: 'iOS', className: 'ios', route: '/docs/ios'
    },
    {
      name: 'WebPlugin', className: 'webplugin', route: '/docs/webplugin'
    },
    {
      name: 'Other Plugins',
      eventKey: 2,
      routes: [
        { name: 'Unity', route: '/docs/unity' },
        { name: 'Cordova', route: '/docs/cordova' },
        { name: 'Flutter', route: '/docs/flutter' },
        { name: 'React Native', route: '/docs/react-native' },
        { name: 'AdMob Android Adapter', route: '/docs/android-admob-adapter' },
        { name: 'AdMob iOS Adapter', route: '/docs/ios-admob-adapter' },
        { name: 'AppLovin Max Android Adapter', route: '/docs/android-max-adapter' },
        { name: 'AppLovin Max iOS Adapter', route: '/docs/ios-max-adapter' },
        { name: 'IronSource Android Adapter', route: '/docs/android-ironsource-adapter' },
        { name: 'IronSource iOS Adapter', route: '/docs/ios-ironsource-adapter' },
      ]
    },
    {
      name: 'Mediation Platform', route: '/docs/mediation'
    },
    {
      name: 'API Documentation', route: '/docs/api-documentation'
    },
    {
      name: 'Pay on Screenouts', route: '/docs/pay-on-screenouts',
    },
    {
      name: 'Dashboard API', route: '/docs/dashboard-api'
    },
    {
      name: 'Server-to-Server Callbacks', className: 's2s', route: '/docs/s2s'
    },
  ]
}];

const Docs = createReactClass({
  propTypes: {
    match: PropTypes.object.isRequired
  },

  contextTypes: {
    location: PropTypes.object.isRequired,
    screen: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    match: PropTypes.object,
  },

  mixins: [ Reflux.listenTo(DocsStore, 'onDataChange') ],

  componentDidMount() {
    this.fetchData(this.props);
    radio('update:header').broadcast({ title: 'SDK & Documentation' });
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps);
    this.setState({
      loading: true
    });
  },

  componentDidUpdate() {
    this.setupDom();
  },

  getInitialState() {
    const name = this.getName(this.props);
    return {
      content: '',
      current: _.findWhere(sdks, { name }),
      loading: true
    };
  },

  onDataChange(data) {
    if (!_.isUndefined(data.content)) {
      const name = this.getName(this.props);
      _.merge(data, {
        loading: false,
        current: _.findWhere(sdks, { name })
      });

      this.setState(data);
    }
  },

  getName(props) {
    const { match } = props;
    const { params } = match;

    return _.compact([params.platform, params.type]).join('/');
  },

  getMeta() {
    return {
      'webplugin': {
        title: 'Website Surveys | WebPlugin SDK - Pollfish',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/${this.context.location.pathname}` }
        ],
        meta: [
          { name: 'description', content: 'A quick setup guide to create website surveys using Pollfish web plugin SDK. Pollfish is a mobile monetization network paying up to 20x more than classic ad networks.' },
          { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
          { property: 'og:title', content: 'Website Surveys | WebPlugin SDK - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'A quick setup guide to create website surveys using Pollfish web plugin SDK. Pollfish is a mobile monetization network paying up to 20x more than classic ad networks.' }
        ]
      },
      'android/google-play': {
        title: 'Quick Guide of Google Play SDK - Pollfish.com',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/${this.context.location.pathname}` }
        ],
        meta: [
          { name: 'description', content: 'Find quick guide of Google Play SDK at Pollfish.com. Using this guide developer can easily integrate Pollfish SDK to your projects.' },
          { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
          { property: 'og:title', content: 'Quick Guide of Google Play SDK - Pollfish.com' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Find quick guide of Google Play SDK at Pollfish.com. Using this guide developer can easily integrate Pollfish SDK to your projects.' }
        ]
      },
      'unity': {
        title: 'Unity Plugin - Pollfish',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/${this.context.location.pathname}` }
        ],
        meta: [
          { name: 'description', content: 'View a detailed guide to easily download Pollfish Unity Plugin and to setup UnitySDK. Obtain a Developer Account and add the app in Pollfish Panel.' },
          { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
          { property: 'og:title', content: 'Download and Setup Unity SDK - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'View a detailed guide to easily download Pollfish Unity Plugin and to setup UnitySDK. Obtain a Developer Account and add the app in Pollfish Panel.' }
        ]
      },
      'corona': {
        title: 'Corona SDK Plugin | Prerequisites and Quick Guide - Pollfish',
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/${this.context.location.pathname}` }
        ],
        meta: [
          { name: 'description', content: 'Allow integration of Pollfish surveys into Android and iOS apps with Pollfish Corona SDK plugin. Scroll through the prerequisites and a quick installation guide here.' },
          { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
          { property: 'og:title', content: 'Corona SDK Plugin | Prerequisites and Quick Guide - Pollfish' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Allow integration of Pollfish surveys into Android and iOS apps with Pollfish Corona SDK plugin. Scroll through the prerequisites and a quick installation guide here.' }
        ]
      },
      'other': {
        title: `${this.state.current.title} · Pollfish`,
        link: [
          { rel: 'canonical', href: `https://www.pollfish.com/${this.context.location.pathname}` }
        ],
        meta: [
          { name: 'description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' },
          { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
          { property: 'og:title', content: 'Pollfish · Publishers' },
          { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
          { property: 'og:description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' }
        ]
      }
    };
  },

  getMetaProps() {
    let props;
    const meta = this.getMeta();
    if (_.isEmpty(this.state.current)) {
      props = false;
    }
    if (this.state.current.name === 'webplugin' ||
      this.state.current.name === 'android/google-play' ||
      this.state.current.name === 'unity' ||
      this.state.current.name === 'unity' ||
      this.state.current.name === 'corona'
    ) {
      props = meta[this.state.current.name];
    } else {
      props = meta.other;
    }

    return props;
  },

  getChangelogEl(version) {
    let query = '.changelog';

    if (version) {
      query = `.changelog[data-version="${version}"]`;
    }

    return ReactDOM.findDOMNode(this).querySelector(query);
  },

  getSidebarItems() {
    let items;
    if (this.context.screen === 'desktop' || this.context.screen === 'large') {
      items = _.first(sidebar).routes;
    } else {
      items = sidebar;
    }

    return items;
  },

  renderButtons() {
    return this.state.current.files.map((file, index) => {
      const title = file.title || 'Download SDK';
      const version = file.version ? `v${ file.version }` : '';

      return (
        <div key={`button-${index}`}>
          <Button className="btn-1c changelog-button" href={ file.url }>
            <span>{ title } </span>
            <span className="version">{ version }</span>
          </Button>
          { file.version
            ?
            <a ref="toggleChangelog" data-version={ file.version } onClick={this.handleClick}>See what's new in this version</a>
            : ''
          }
        </div>
      );
    });
  },

  renderContent() {
    return (
      <div>
        <div>
          <h1 className="static-page-title">{ this.state.current.title }</h1>
        </div>
        { this.renderButtons() }
        <Markdown options={ { html: true } } source={this.state.content} />
      </div>
    );
  },

  renderSpinner() {
    return <Loader />;
  },

  renderDocs() {
    let sidebarCont;
    let cont;

    if (this.context.screen === 'mobile' || (this.context.screen === 'tablet' && this.context.orientation === 'portrait')) {
      sidebarCont = (
        <Col lg={4} md={4} xs={12} className="sidebar-static mobile">
          <Sidebar items={this.getSidebarItems()} />
        </Col>
      );
    } else {
      sidebarCont = (
        <Col lg={4} md={4} xs={12} className="sidebar-static">
          <Sidebar items={menuLinks()} />
        </Col>
      );
    }

    if (this.context.screen === 'large') {
      cont = (
        <Grid fluid>
          <Row>
            { sidebarCont }
            <Col id="document" ref="docsContent" className="docs-content"  lg={8} md={8} xs={12}>
              {
                this.state.loading
                  ? this.renderSpinner()
                  : this.renderContent()
              }
            </Col>
          </Row>
        </Grid>
      );
    } else {
      cont = (
        <Grid>
          <Row>
            { sidebarCont }
            <Col id="document" ref="docsContent" className="docs-content"  lg={8} md={8} xs={12}>
              {
                this.state.content
                  ? this.renderContent()
                  : this.renderSpinner()
              }
            </Col>
          </Row>
        </Grid>
      );
    }

    return cont;
  },

  render() {
    return (
      <div className="docs-page">
        <Helmet {...this.getMetaProps()} />
        { this.renderDocs() }
        <Partners className="footer-single-page" />
      </div>
    );
  },

  setupDom() {
    const $document = ReactDOM.findDOMNode(this.refs.docsContent);
    const $el = this.getChangelogEl();
    const $toggleChangelog = ReactDOM.findDOMNode(this.refs.toggleChangelog);
    const code = $document.querySelectorAll('pre code');
    const links = $document.querySelectorAll('a');

    if ($el && $toggleChangelog) {
      $el.style.display = 'none';
      $toggleChangelog.style.display = 'initial';
    } else if ($toggleChangelog) {
      $toggleChangelog.style.display = 'none';
    }

    _.each(code, (block) => {
      highlight.highlightBlock(block);
    });

    _.each(links, (link) => {
      link.setAttribute('target', '_blank');
    });

    this.cleanChangelogButtons();
  },

  fetchData(props) {
    const { match } = props;
    const { params } = match;

    if (!params.type) {
      props.match.params.type = undefined;
    }

    DocsActions.set(params);
    DocsActions.fetch();
  },

  cleanChangelogButtons() {
    const $document = ReactDOM.findDOMNode(this).querySelector('.docs-content');
    if ($document && !_.isEmpty(this.state.current)) {
      _.each(this.state.current.files, ({ version }) => {
        const changelogButton = $document.querySelector(`a[data-version="${version}"]`);
        const versionExists = !!~this.state.content.search(`data-version="${version}"`);
        if (changelogButton) {
          if (!versionExists) {
            changelogButton.style.display = 'none';
          } else {
            changelogButton.style.display = 'initial';
          }
        }
      });
    }
  },

  handleClick(ev) {
    ev.preventDefault();
    const $el = this.getChangelogEl(ev.target.dataset.version);
    $el.className += ' well';

    if ($el.style.display === 'block') {
      $el.style.display = 'none';
    } else {
      $el.style.display = 'block';
    }
  }

});

export default Docs;
