import _ from 'lodash';
import { Entity } from 'relapse';

class Session extends Entity {

  constructor() {
    super(undefined, {
      headers: {
        'Accept': 'application/vnd.github.VERSION.raw+json',
        'Authorization': 'token 89933d8c98c138e812241d9b99bc668100883904'
      }
    });
  }

  parse(data) {
    this.set('content', data);
  }

  getFilename() {
    return _.compact([this.get('platform'), this.get('type')]).join('/');
  }

  url() {
    const filename = this.getFilename();
    return `https://api.github.com/repos/pollfish/docs/contents/${filename}.md`;
  }

}

export default Session;

