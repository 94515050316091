import React from 'react';
import createReactClass from 'create-react-class';
import Table from 'react-bootstrap/lib/Table';
import SinglePage from 'single_page';
import Helmet from 'react-helmet';

const Referral = createReactClass({

  getMetaProps() {
    return {
      title: 'Referral Bonus Program | Earn Money through Surveys - Pollfish',
      link: [
        { rel: 'canonical', href: 'https://www.pollfish.com/publisher/referral' }
      ],
      meta: [
        { name: 'description', content: 'Introduce Pollfish to your developer friends. The referral bonus program is designed to yield win-win solutions for both you and your referred friends.' },
        { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
        { property: 'og:title', content: 'Referral Bonus Program | Earn Money through Surveys - Pollfish' },
        { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
        { property: 'og:description', content: 'Introduce Pollfish to your developer friends. The referral bonus program is designed to yield win-win solutions for both you and your referred friends.' }
      ]
    };
  },

  renderContent() {
    return (
      <div className="referral">
        <Helmet {...this.getMetaProps()} />
        <h2 className="static-page-title">Introduce Pollfish to your developer friends</h2>
        <p>Our referral bonus program is designed to provide win-win solutions both to you and your referred friends.</p>
        <Table responsive>
          <thead>
            <tr>
              <th>Completed surveys *</th>
              <th>Your friend</th>
              <th>You get</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>500</td>
              <td>$20</td>
              <td>$20</td>
            </tr>
            <tr>
              <td>1,000</td>
              <td>$40</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>5,000</td>
              <td>-</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>10,000</td>
              <td>-</td>
              <td>$400</td>
            </tr>
            <tr>
              <td>20,000</td>
              <td>-</td>
              <td>$800</td>
            </tr>
            <tr>
              <td>50,000</td>
              <td>-</td>
              <td>$1700</td>
            </tr>
          </tbody>
        </Table>
        <ul>
          <li>
            <span className="list-number">1</span>
            Sign up or Log into your Pollfish Developer panel
          </li>
          <li>
            <span className="list-number">2</span>
            Click on the Referral program button in the top menu
          </li>
          <li>
            <span className="list-number">3</span>
            On this page you will find your own referral link
          </li>
          <li>
            <span className="list-number">4</span>
            Invite developers via email, facebook, twitter, blog post or any other way
          </li>
        </ul>
      </div>
    );
  },

  render() {
    return (
      <SinglePage
        title="Referral"
        content={ this.renderContent() } />
    );
  }

});

export default Referral;

