import React from 'react';

const LandscapeWarning = () =>
  <div id="warning-message">
    <div className="logo">
      <i className="icon-logo" />
    </div>
    <div className="image">
      <img src="/homeassets/images/rotate_phone.svg" width="200" />
    </div>
    <div className="message">
      Please rotate your device or enjoy this beautiful landscape
    </div>
  </div>

export default LandscapeWarning;

