import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Testimonials from '../common/testimonials';

const logos = [
  'oracle',
  'microsoft',
  'tmobile',
  'millward',
  'omd',
  'mondelez'
];

const Customers = createReactClass({

  propTypes: {
    className: PropTypes.string
  },

  getClassNames() {
    return cx(this.props.className, {
      'section-inner': true,
      'customers': true
    });
  },

  render() {
    return (
      <div {...this.props} className={this.getClassNames()}>
        <Testimonials
          iconFont
          description={<div className="description">Pollfish is transforming survey intelligence with the most accurate, cost effective results and the quickest survey completion times. <br />We are proud of the professional experience we have gained over the years by working for both small and high-profile clients.</div>}
          logos={logos} />
      </div>
    );
  }
});

export default Customers;

