const urlParamsToObject = (params) => {
  let result = {}

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export default urlParamsToObject;

