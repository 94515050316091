import Reflux from 'reflux';
import DocsAction from '../actions/docs_actions';
import Docs from '../entities/docs';

const DocsStore = Reflux.createStore({
  listenables: [DocsAction],

  init() {
    this.docs = new Docs();
    this.docs.on('data:change', this.updateData);
  },

  onSet(data) {
    this.docs.set(data);
  },

  onFetch() {
    this.docs.fetch();
  },

  updateData() {
    this.trigger(this.docs.toJSON());
  }

});

export default DocsStore;

