import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import { PanelGroup, Panel } from 'react-bootstrap';

const Sidebar = createReactClass({

  propTypes: {
    items: PropTypes.array.isRequired,
    full: PropTypes.bool
  },

  contextTypes: {
    location: PropTypes.object.isRequired,
    screen: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    match: PropTypes.object,
  },

  getInitialState() {
    return {
      activeKey: 0,
      activeRoute: ''
    };
  },

  UNSAFE_componentWillMount() {
    let activeKey;
    let secondaryActiveKey;
    let activeRoute;
    const items = this.getNavItems();
    [ activeKey, secondaryActiveKey, activeRoute ] = this.getRoutes(items);

    this.setState({ items, activeKey, secondaryActiveKey, activeRoute });
  },

  getRoutes(items) {
    let activeKey;
    let secondaryActiveKey;
    let activeRoute;

    _.some(items, (current) => {
      let routes;
      if (!current.routes && current.route) {
        routes = [ current ];
      } else {
        routes = current.routes;
      }

      activeKey = current.eventKey;
      return _.some(routes, (link) => {
        if (link.route && this.context.location.pathname === link.route) {
          if (activeKey) {
            secondaryActiveKey = current.eventKey;
          } else {
            activeKey = current.eventKey;
          }
          activeRoute = this.context.location.pathname;
          return true;
        } else if (link.routes) {
          return _.some(link.routes, (l) => {
            if (this.context.location.pathname === l.route) {
              secondaryActiveKey = link.eventKey;
              return true;
            }
          });
        }
      });
    });

    return [ activeKey, secondaryActiveKey, activeRoute ];
  },

  getNavItems() {
    let items;
    let name = this.extractCategory();
    if (name === 'Terms') {
      name = 'Terms of Service';
    }

    if ((this.context.screen === 'desktop' || this.context.screen === 'large' ||
        (this.context.screen === 'tablet' && this.context.orientation === 'landscape')) || this.props.full) {
      items = this.props.items;
    } else {
      const currentPage = _.chain(this.props.items)
          .find({ name })
          .clone()
          .value();

      if (!_.isUndefined(currentPage)) {
        currentPage.name = this.getHeaderMenu();
      }

      items = currentPage ? [ currentPage ] : this.props.items;
    }

    return items;
  },

  getHeaderMenu() {
    let header;
    const pathname = this.context.location.pathname;

    if (/docs/.test(pathname)) {
      header = 'Select SDK';
    } else {
      header = 'Menu';
    }

    return header;
  },

  renderLinks(routes) {
    return routes.map((link, index) => {
      const key = `list-item-${index}`;

      return (
        link.route ?
        <NavLink
          to={link.route}
          className="list-group-item"
          activeClassName="active"
          exact
          key={key}
        >
          {link.name}
        </NavLink> :
        <a key={key} href={link.url} target="_blank" className="list-group-item">
          {link.name}
        </a>
      );
    });
  },

  renderPanel(item, index) {
    let key = this.state.activeKey;

    if (item.eventKey === this.state.secondaryActiveKey) {
      key = this.state.secondaryActiveKey;
    }

    return (
      <PanelGroup accordion key={`panel-${index}`} header={item.name} defaultActiveKey={key} id={`panel-${index}`}>
        <Panel eventKey={item.eventKey}>
          <Panel.Heading>
            <Panel.Title toggle>{item.name}</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
          { this.renderPanels(item.routes) }
          </Panel.Body>
        </Panel>
      </PanelGroup>
    );
  },

  renderPanels(items) {
    return items.map((item, index) => {
      let content;

      if (item.routes) {
        content = this.renderPanel(item, index);
      } else {
        content = this.renderLinks([ item ]);
      }

      return content;
    });
  },

  render() {
    return (
      <PanelGroup accordion defaultActiveKey={this.state.activeKey} id="main-panel-group">
        { this.renderPanels(this.getNavItems()) }
      </PanelGroup>
    );
  },

  extractCategory() {
    let pathname = this.context.location.pathname.split('/')[1];

    if (pathname === 'publisher') {
      pathname = '';
    }

    return capitalize(pathname);
  },

  isActiveTab(currentRoute) {
    let active = false;
    if (this.context.location.pathname === currentRoute) {
      active = true;
    }

    return active;
  }
});

export default Sidebar;
