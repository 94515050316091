import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import SocialButton from '../common/social_button';

const Colophon = createReactClass({

  contextTypes: {
    screen: PropTypes.string.isRequired
  },

  render() {
    const screen = this.context.screen;
    let terms;

    if (screen === 'mobile') {
      terms = (
        <p className="mobile-terms">
          <Link to="/terms/researcher">Terms Of Service</Link>
        </p>
      );
    } else {
      terms = (
        <div>
          <p>Terms of Service: <Link to="/terms/researcher">Researcher</Link> | <Link to="/terms/publisher">Publisher</Link> | <Link to="/terms/respondent">Respondent</Link></p>
          <p> © { new Date().getFullYear() } Pollfish. All Rights Reserved </p>
        </div>
      );
    }
    return (
      <div className="colophon">
        <Row className="show-grid">
          <Col xs={12} sm={12} md={6} lg={6} className="big-button">
            <a href="/contact" className="contact-button btn btn-lg btn-primary">Contact <span>→</span></a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="contact-info" >
            <Row>
              <Col xs={7} md={6} lg={4} className="social-icons">
                <SocialButton type="facebook" />
                <SocialButton type="twitter" />
                <SocialButton type="linkedin" />
              </Col>
              <Col xs={5} md={6} lg={8} className="company-info">
                { terms }
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
});

export default Colophon;

