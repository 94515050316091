import { defineMessages } from 'react-intl';

export default defineMessages({
  acceptAll: {
    id: 'app.components.CookieConsent.acceptAll',
    defaultMessage: 'Accept All',
  },
  manageCookiePrefs: {
    id: 'app.components.CookieConsent.manageCookiePrefs',
    defaultMessage: 'Manage Cookie Preferences',
  },
  websiteCookiePrefs: {
    id: 'app.components.CookieConsent.websiteCookiePrefs',
    defaultMessage: 'Website data collection preferences',
  },
  cookieModalTitle: {
    id: 'app.components.CookieConsent.cookieModalTitle',
    defaultMessage: 'The table below outlines how we use this data by category. To opt out of a category of data collection, select "Do not allow" and save your preferences.',
  },
  advertising: {
    id: 'app.components.CookieConsent.advertising',
    defaultMessage: '<p>To personalize and measure the effectiveness of advertising on our site and other websites.</p>' +
    '<p>For example, we may serve you a personalized ad based on the pages you visit on our site.</p>',
  },
  marketingAndAnalytics: {
    id: 'app.components.CookieConsent.advertising',
    defaultMessage: '<p>To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.</p>' +
    '<p>For example, we collect information about which pages you visit to help us present more relevant information.</p>',
  },
  functional: {
    id: 'app.components.CookieConsent.advertising',
    defaultMessage: '<p>To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.</p>' +
    '<p>For example, we collect information about which pages you visit to help us present more relevant information.</p>',
  },
  bannerContent: {
    id: 'app.components.CookieConsent.bannerContent',
    defaultMessage: '<p class="cookie-consent__banner-title">' +
    'We use cookies' +
    '</p>'
  },
  bannerSubContent: {
    id: 'app.components.CookieConsent.bannerSubContent',
    defaultMessage: '<p class="cookie-consent__banner-content">' +
    '  In order to give you the best experience,' +
    '  Pollfish and our third party partners may use cookies and similar technologies,' +
    '  for example, to analyze usage and optimize our sites and services,' +
    '  personalize content, tailor and measure our marketing and to keep the site secure.' +
    '</p>'
  }
});
