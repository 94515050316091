import _ from 'lodash';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import LoginForm from '../entities/login';
import LoginActions from '../actions/login_actions';
import request from 'superagent';
import capitalize from 'capitalize';
import notify from 'notify';
import cookie from 'js-cookie';
import Raven from 'raven-js';

const LoginStore = Reflux.createStore({
  listenables: [LoginActions],

  contextTypes: {
    router: PropTypes.func.isRequired
  },

  init() {
    this.loginForm = new LoginForm();
    request
      .get('/api/v2/user')
      .end((err, res) => {
        if (err) {
          return false;
        }
        if (res) {
          const body = res.body || _.attempt(() => JSON.parse(res.text));
          if (!body.error) {
            setTimeout(() => {
              analytics.identify(body.id, {
                email: body.email
              });
            }, 1000);
            this.loginForm.parse(body);
          }
        }
      });
    this.loginForm.on('data:change', this.updateData);
    this.loginForm.on('login:success', this.updateData);
    this.loginForm.on('validation:done', this.validation);
  },

  onLogin(role) {
    if (this.loginForm.validate().valid) {
      const data = this.loginForm.toJSON();
      data.email = data.email.toLowerCase();

      request
        .post('/api/v2/user/login')
        .query(data)
        .end((err, res) => {
          if (err && !_.isUndefined(window.Raven)) {
            Raven.captureException(err, { extra: { where: 'loginUser' } });
          }
          if (res) {
            const body = res.body || _.attempt(() => JSON.parse(res.text));
            if (body.error) {
              notify({
                message: capitalize(body.error),
                level: 'error'
              });
              this.trigger({ loading: false });
            }
            if (cookie.get('offer')) {
              this.sendOffer(cookie.get('offer'));
            } else if (cookie.get(`${role}Referral`)) {
              this.markReferral(role);
            } else {
              this.loginForm.parse(body);
            }
          }
        });
    }
  },

  sendOffer(offerCode, isLoggedIn = true) {
    request
      .post('/api/v2/user/offer/set')
      .query({ offer_code: offerCode })
      .end((err, res) => {
        if (err && !_.isUndefined(window.Raven)) {
          Raven.captureException(err, { extra: { where: 'setOffer' } });
        }
        cookie.remove('offer');
        if (isLoggedIn && res) {
          this.loginForm.parse(res.body);
        }
      });
  },

  onGoogleLogin(token, role) {
    let isDeveloper = false;
    if (role === 'publisher') {
      isDeveloper = true;
    }
    request
      .post('/api/v2/user/authenticate/google')
      .query({ token, isDeveloper })
      .end((err, res) => {
        if (err && !_.isUndefined(window.Raven)) {
          Raven.captureException(err, { extra: { where: 'googleLogin' } });
        }
        if (res) {
          const body = res.body || _.attempt(() => JSON.parse(res.text));
          if (body.is_new) {
            this.logAnalyticEvents(role);
            analytics.alias(body.id);
          }

          if (cookie.get('offer')) {
            this.sendOffer(cookie.get('offer'));
          } else if (cookie.get(`${role}Referral`)) {
            this.markReferral(role);
          } else {
            setTimeout(() => {
              analytics.identify(body.id, {
                email: body.email
              });
            }, 1000);
            this.loginForm.parse(body);
          }
        }
      });
  },

  logAnalyticEvents(role) {
    if (window.location.host === 'www.pollfish.com') {
      // ensures the optimizely object is defined globally using
      window.optimizely = window.optimizely || [];

      if (role === 'researcher') {
        analytics.track('register', {
          category: 'researcher'
        });
        // sends a tracking call to Optimizely for the given event name.
        window.optimizely.push(['trackEvent', 'researcherRegister']);
      } else {
        analytics.track('register', {
          category: 'developer'
        });
        // sends a tracking call to Optimizely for the given event name.
        window.optimizely.push(['trackEvent', 'developerRegister']);
      }
    }
  },

  markReferral(role) {
    const key = `${role}Referral`;
    let url;
    const refId = cookie.get(key);

    if (!refId) {
      return false;
    }

    if (role === 'researcher') {
      url = '/api/v2/user/markResearcherReferral';
    } else {
      url = '/api/v2/user/markReferral';
    }

    request
      .post(url)
      .query({ ref_id: refId })
      .end((err, res) => {
        if (err && !_.isUndefined(window.Raven)) {
          Raven.captureException(err, { extra: { where: 'markReferral' } });
        }
        cookie.remove(key);
        if (res) {
          this.loginForm.parse(res.body);
        }
      });
  },

  onSet(data) {
    this.loginForm.set(data);
  },

  updateData() {
    this.trigger(this.loginForm.toJSON());
  },

  validation(validation) {
    const errors = {};

    _.each(validation.errors, function(error) {
      errors[error.property] = error.message;
    });

    this.trigger({validation: {errors}});
  }

});

export default LoginStore;

