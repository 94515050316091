import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

const Loader = createReactClass({

  propTypes: {
    container: PropTypes.object,
    image: PropTypes.object
  },

  getDefaultProps() {
    return {
      container: {
        width: '100%'
      },
      image: {
        width: '120px',
        display: 'block',
        margin: '50px auto'
      }
    };
  },

  render() {
    return (
      <div style={this.props.container}>
        <img style={this.props.image} src="/homeassets/images/preloader.gif" />
      </div>
    );
  }
});

export default Loader;
