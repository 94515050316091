import { Entity } from 'relapse';
import resetValidations from './validations/reset_validations.js';

class ResetForm extends Entity {
  constructor() {
    super(undefined, {
      url: '/api/v2/user/request/new',
      validations: resetValidations
    });
  }

  parse(data) {
    this.store = data;
    if (data.email) {
      this.emit('reset:success', data);
    }
  }
}

export default ResetForm;

