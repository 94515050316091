"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

// this needs to be set to true before Keep.js starts storing, done via useKeep
var use = false;

var createdStores = [];

var createdActions = [];

function useKeep() {
	var bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

	use = bool;
}

function addStore(str) {
	if (use) {
		createdStores.push(str);
	}
}

function addAction(act) {
	if (use) {
		createdActions.push(act);
	}
}

function reset() {
	while (createdStores.length) {
		createdStores.pop();
	}
	while (createdActions.length) {
		createdActions.pop();
	}
}

exports.useKeep = useKeep;
exports.addStore = addStore;
exports.addAction = addAction;
exports.createdStores = createdStores;
exports.createdActions = createdActions;
exports.reset = reset;