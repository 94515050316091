import React from 'react';
import { ConsentManagerBuilder, loadPreferences } from '@segment/consent-manager';
import { isUndefined, find, forEach, sortBy } from 'lodash';
import Modal from 'react-modal';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Raven from 'raven-js';
import { CATEGORIES_MAP, CATEGORIES_DICTIONARY } from './constants';
import {isDestinationInCategory, getCategoryByDestinations } from './utils';
import messages from './messages';

const acceptAllChoicesPayload = {
  advertising: true,
  functional: true,
  marketingAndAnalytics: true,
};

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);

    const currentPrefs = loadPreferences();
    const savedPrefs = !isUndefined(currentPrefs.destinationPreferences) || !isUndefined(currentPrefs.customPreferences);

    this.state = {
      isModalOpen: false,
      savedPrefs,
      customPreferences: acceptAllChoicesPayload,
    };
    if(!savedPrefs) {
      document.body.classList.add('show-cookie-consent');
    }

    this.setAllPrefsToBool = this.setAllPrefsToBool.bind(this);
    this.setPrefsForCategory = this.setPrefsForCategory.bind(this);
    this.setModalVisible = this.setModalVisible.bind(this);
    this.updateConsentHandler = this.updateConsentHandler.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  setAllPrefsToBool({ categoryNames, enabled, setPreferences, destinations }) {
    let category;
    let updatedCustomPreferences = {};

    forEach(categoryNames, (categoryName) => {
      category = find(CATEGORIES_MAP, {name: categoryName});

      forEach(destinations, (destination) => {
        if(isDestinationInCategory(category, destination)) {
          setPreferences({[destination.id]: enabled});
        }
      });
      updatedCustomPreferences[categoryName] = enabled;
    });

    this.setState({
      customPreferences: updatedCustomPreferences
    })
  };

  setPrefsForCategory ({ categoryName, enabled, setPreferences, destinations }) {
    const category = find(CATEGORIES_MAP, {name: categoryName});
    forEach(destinations, (destination) => {
      if(isDestinationInCategory(category, destination)) {
        setPreferences({[destination.id]: enabled});
      }
    });
    this.setState((state) => {
      const { customPreferences } = state;
      return {
        customPreferences: Object.assign({}, customPreferences, {[categoryName]: enabled })
      }
    })
  };

  setModalVisible(isModalOpen) {
    this.setState({isModalOpen});
  };

  showModal() {
    if (this.preferencesSet) {
      this.setAllPrefsToBool(this.preferencesSet);
      this.setModalVisible(true);
    }
  }

  updateConsentHandler() {
    this.setState({
      isModalOpen: false,
      savedPrefs: true,
    });
  };

  renderContent({ destinations = [], preferences, setPreferences, saveConsent }) {
    const { savedPrefs, isModalOpen } = this.state;
    const { displayInfo } = this.props;

    const categories = getCategoryByDestinations(destinations);
    const categoryKeys = sortBy(Object.keys(categories), (category) => category < 'functional').reverse();
    const preferencesSet = {
      categoryNames: Object.keys(categories),
      enabled: true,
      setPreferences,
      destinations,
    };
    this.preferencesSet = preferencesSet;

    return (
      <div>
        {displayInfo && (
        <div className="cookie-consent display-flex-column flex-justify-content--between flex-align-items--center">
          <div className="display-flex flex-justify-content--between">
            <div className="cookie-consent__icon">
              <i className="icon icon-cookie-consent"></i>
            </div>
            <div className="text-left">
              <FormattedHTMLMessage {...messages.bannerContent}/>
              <FormattedHTMLMessage {...messages.bannerSubContent}/>
            </div>
          </div>
          {!savedPrefs && (
            <div className="cookie-consent__banner-menu-wrapper display-flex flex-justify-content--between flex-align-items--center">
              <div className="cookie-consent__banner-menu display-flex flex-justify-content--between">
                <button className="cookie-consent__pref-btn" type="button" onClick={() => {

                  this.setAllPrefsToBool(preferencesSet);
                  this.setModalVisible(true);
                }}>
                  <FormattedMessage {...messages.manageCookiePrefs} />
                </button>
                <button className="cookie-consent__btn cookie-consent__btn--primary" type="button" onClick={() => {
                  saveConsent(true);
                  this.updateConsentHandler();
                }}>
                  <FormattedMessage {...messages.acceptAll} />
                </button>
              </div>
              <button
                title="Cancel"
                aria-label="cancel"
                className="cookie-consent__banner-close"
                type="button"
                onClick={() => {
                  saveConsent(true);
                  this.updateConsentHandler();
                }}
              >
                &times;
              </button>
            </div>
          )}
        </div>)}

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => this.setModalVisible(false)}
          ariaHideApp={false}
        >
          <div className="pf-modal__header">
            <h2 className="pf-modal__title">
              <FormattedMessage {...messages.websiteCookiePrefs} />
            </h2>
            <button
              className="pf-modal__close-btn"
              onClick={() => this.setModalVisible(false)}
              title="Cancel"
              aria-label="cancel"
              type="button"
            >
              &times;
            </button>
          </div>
          <div className="pf-modal__body">
            <p className="cookie-consent__modal-title">
              <FormattedMessage {...messages.cookieModalTitle} />
            </p>
                <table className="cookie-consent__table">
                  <thead>
                    <tr>
                      <th style={{width: 120}}>Allow</th>
                      <th>Category</th>
                      <th>Purpose</th>
                      <th>Tools</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryKeys.map(cat =>  {
                      const destinationsPerCategory = categories[cat];

                      return (
                        <tr key={cat}>
                          <td>
                            {cat !== 'functional' ? (
                              <div>
                                <label className="display-block">
                                  <input
                                    type="radio"
                                    name={cat}
                                    onChange={() => {
                                      this.setPrefsForCategory({
                                        categoryName: cat,
                                        enabled: true,
                                        setPreferences,
                                        destinations,
                                      });
                                    }}
                                    defaultChecked={true}
                                  />
                                  <span className="pf-checkbox-label">Allow</span>
                                </label>
                                <label className="display-block">
                                  <input
                                    type="radio"
                                    name={cat}
                                    onChange={() => {
                                      this.setPrefsForCategory({
                                        categoryName: cat,
                                        enabled: false,
                                        setPreferences,
                                        destinations,
                                      });
                                    }}
                                  />
                                  <span className="pf-checkbox-label">Do not allow</span>
                                </label>
                              </div>
                            ) : (<span></span>)}
                          </td>
                          <td>
                            {CATEGORIES_DICTIONARY[cat]}
                          </td>
                          <td>
                            <FormattedHTMLMessage {...messages[cat]} />
                          </td>
                          <td>
                            <ul className="cookie-consent__list">
                              {destinationsPerCategory.map((destination) => (
                                <li key={destination.id}>
                                    {destination.name}
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
          </div>
          <div className="pf-modal__footer">
            <button className="cookie-consent__btn" type="button" onClick={() => this.setModalVisible(false)}>Cancel</button>
            <button style={{marginLeft: 4}} className="cookie-consent__btn cookie-consent__btn--primary" type="button" onClick={() => {
              saveConsent();
              this.updateConsentHandler();
            }}>Save</button>
          </div>
        </Modal>
      </div>)
  };

  render() {
    const { savedPrefs } = this.state;

    return (
      <ConsentManagerBuilder
        writeKey="aevrmv2nk1"
        shouldRequireConsent={() => !savedPrefs}
        closeBehavior="accept"
        onError={(e) => {
          Raven.captureException('Segment integration fail to fetch', {
            extra: {
              error: e
            }
          });
          //we expect that this will fail due to an ad blocker.
          //Below we use the analytics api to load the scripts that we have labeled as required/functional.
          //https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
          if(window.analytics && window.analytics.load) {
            window.analytics.load("aevrmv2nk1", {
              integrations: {
                All: false, 'Customer.io': true, 'HubSpot': true, 'Intercom': true,
              }
            });
            window.analytics.page && window.analytics.page();
          }
        }}
      >
        {this.renderContent}
      </ConsentManagerBuilder>
    );
  }
}

CookieConsent.defaultProps = {
  displayInfo: false
};

export default CookieConsent;
