import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Grid from 'react-bootstrap/lib/Grid';
import LandscapeWarning from 'landscape_warning';
import Header from './header.jsx';
import ReactResponsiveMixin from 'react-responsive-mixin';
import { MountResponsiveMixin } from '../mixins';
import scrollTo from '../../util/scrollTo';
import Notification from './notification';

import { getMuiTheme } from 'material-ui/styles';

const Layout = createReactClass({

  propTypes: {
    location: PropTypes.object,
    match: PropTypes.object,
    children: PropTypes.object
  },

  childContextTypes: {
    muiTheme: PropTypes.object,
    screen: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    orientation: PropTypes.string
  },

  mixins: [ ReactResponsiveMixin, MountResponsiveMixin ],

  getInitialState() {
    return {
      orientation: 'portrait',
      screen: 'desktop'
    };
  },

  componentDidMount() {
    this.scrollTop();
  },

  UNSAFE_componentWillUpdate() {
    this.scrollTop();
  },

  getChildContext() {
    return {
      location: this.props.location,
      match: this.props.match,
      muiTheme: getMuiTheme(),
      screen: this.state.screen,
      orientation: this.state.orientation
    };
  },

  renderWarning() {
    return <LandscapeWarning />;
  },

  renderContent() {
    return (
      <Grid className="full" fluid>
        <Header />
        { this.props.children }
        <Notification />
      </Grid>
    );
  },

  render: function() {
    let content;

    if (this.state.orientation === 'landscape' && this.state.screen === 'mobile') {
      content = this.renderWarning();
    } else {
      content = this.renderContent();
    }

    return content;
  },

  scrollTop() {
    scrollTo(document.getElementsByTagName('body')[0], 0, 200);
  }

});

export default withRouter(Layout);

