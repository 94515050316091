const menuLinks = () => {
  return [
    {
      name: 'Company',
      eventKey: 1,
      routes: [
        { name: 'Media Kit', ref: 'mediakit', route: '/company/mediakit' }
      ]
    },
    {
      name: 'Researcher',
      routes: [
        { name: 'Example Results', target: '_blank', url: 'https://www.pollfish.com/dashboard/results/253227526/618845688' },
        { name: 'Case Studies', target: '_blank', url: 'http://blog.pollfish.com/category/market-research' }
      ]
    },
    {
      name: 'Publisher',
      eventKey: 2,
      routes: [
        { name: 'Referral', ref: 'referral', route: '/publisher/referral' },
        { name: 'Case Studies', target: '_blank', url: 'https://pollfish.com/case-studies/' },
        { name: 'Demo Apps', ref: 'demo-app', route: '/publisher/demo' },
        { name: 'Testimonials', ref: 'testimonials', route: '/publisher/testimonials' }
      ]
    },
    {
      name: 'SDK & Documentation',
      eventKey: 3,
      routes: [
        {
          name: 'Android',
          eventKey: 5,
          routes: [
            { name: 'Google Play Edition', route: '/docs/android/google-play' },
            { name: 'Universal Edition', route: '/docs/android/universal' }
          ]
        },
        {
          name: 'Android v7 (Beta)', eventKey: 17, route: '/docs/android-v7'
        },
        {
          name: 'iOS', eventKey: 16, className: 'ios', route: '/docs/ios'
        },
        {
          name: 'WebPlugin', eventKey: 4, className: 'webplugin', route: '/docs/webplugin'
        },
        {
          name: 'Other Plugins',
          eventKey: 6,
          routes: [
            { name: 'Unity', route: '/docs/unity' },
            { name: 'Cordova', route: '/docs/cordova' },
            { name: 'Flutter', route: '/docs/flutter' },
            { name: 'React Native', route: '/docs/react-native' },
            { name: 'AdMob Android Adapter', route: '/docs/android-admob-adapter' },
            { name: 'AdMob iOS Adapter', route: '/docs/ios-admob-adapter' },
            { name: 'AppLovin Max Android Adapter', route: '/docs/android-max-adapter' },
            { name: 'AppLovin Max iOS Adapter', route: '/docs/ios-max-adapter' },
            { name: 'IronSource Android Adapter', route: '/docs/android-ironsource-adapter' },
            { name: 'IronSource iOS Adapter', route: '/docs/ios-ironsource-adapter' },
          ]
        },
        {
          name: 'Rewarded Surveys', eventKey: 7, className: 'rewarded-surveys', route: '/docs/rewarded-surveys'
        },
        {
          name: 'Offerwall', eventKey: 9, className: 'offerwall', route: '/docs/offerwall'
        },
        {
          name: 'Pay on Screenouts', eventKey: 10, className: 'pay-on-screenouts', route: '/docs/pay-on-screenouts'
        },
        {
          name: 'Demographic Surveys', eventKey: 11, className: 'demographic-surveys', route: '/docs/demographic-surveys'
        },
        {
          name: 'Mediation Platform', eventKey: 12, route: '/docs/mediation'
        },
        {
          name: 'API Documentation', eventKey: 13, route: '/docs/api-documentation'
        },
        {
          name: 'Dashboard API', eventKey: 14, route: '/docs/dashboard-api'
        },
        {
          name: 'Server-to-Server Callbacks', eventKey: 8, className: 's2s', route: '/docs/s2s'
        }
      ]
    },
    {
      name: 'Respondent',
      eventKey: 8,
      routes: [
        { name: 'Opt Out', ref: 'opt-out', route: '/respondent/opt-out' }
      ]
    },
    {
      name: 'Terms of Service',
      eventKey: 9,
      routes: [
        { name: 'Researcher', ref: 'termsResearcher', route: '/terms/researcher' },
        { name: 'Subscription Plans', ref: 'termsSubscriptionPlans', route: '/terms/subscriptions' },
        { name: 'Researcher - Distribution Link', ref: 'termsResearcherLink', route: '/terms/researcher-link' },
        { name: 'Publisher', ref: 'termsPublisher', route: '/terms/publisher' },
        { name: 'Respondent', ref: 'termsRespondent', route: '/terms/respondent' },
        { name: 'Respondent - Distribution Link', ref: 'termsRespondentLink', route: '/terms/respondent-link' },
        { name: 'Cookie Policy', ref: 'cookiePolicy', route: '/terms/cookie-policy' },
        { name: 'Google Play Data Safety Questionnaire', ref: 'googlePlayDataSafetyQuestionnaire', route: '/terms/google-play-data-safety-questionnaire' }
      ]
    }
  ];
};

export default menuLinks;

