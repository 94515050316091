import React from 'react';
import createReactClass from 'create-react-class';
import SinglePage from 'single_page';
import Button from 'react-bootstrap/lib/Button';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Helmet from 'react-helmet';

const MediaKit = createReactClass({

  getMetaProps() {
    return {
      title: 'Media Kit · Pollfish',
      link: [
        { rel: 'canonical', href: 'https://www.pollfish.com/company/mediakit' }
      ],
      meta: [
        { name: 'description', content: 'Pollfish\'s online survey tool delivers quick survey results globally through millions of survey repondents who take your survey. This software ensures cost-effective and quick survey results.' },
        { name: 'keywords', content: 'online survey tools, survey tools, online survey software, survey, mobile, smartphone, fast, accurate, poll, market research' },
        { property: 'og:title', content: 'Media Kit · Pollfish' },
        { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
        { property: 'og:description', content: 'Pollfish\'s online survey tool delivers quick survey results globally through millions of survey repondents who take your survey. This software ensures cost-effective and quick survey results.' }

      ]
    };
  },

  renderContent() {
    return (
      <div className="mediakit">
        <Helmet {...this.getMetaProps()} />
        <h1 className="static-page-title">
          Media Kit
          <Button className="btn-1c mediakit-button" target="_blank" href="/homeassets/mediakit/pollfish-media-kit.zip">
            <i className="icon-file-archive-o"></i>
            <span>Download Zip</span>
          </Button>
        </h1>
        <h3>Primary Brand Colors</h3>
        <Row>
          <Col xs={6} md={4} className="brand-colors">
              <div className="color-container" style={ { backgroundColor: '#E44044' } }>
                <div className="top-left">#E44044</div>
                <div className="top-right">R228 G64 B68</div>
                <div className="clearer"></div>
                <div className="bottom-right">C05 M90 Y75 K00</div>
              </div>
          </Col>
          <Col xs={6} md={4} className="brand-colors">
              <div className="color-container" style={ { backgroundColor: '#373E4A' } }>
                <div className="top-left">#373E4A</div>
                <div className="top-right">R55 G62 B74</div>
                <div className="clearer"></div>
                <div className="bottom-right">C77 M66 Y51 K42</div>
              </div>
          </Col>
          <Col xs={6} md={4} className="brand-colors">
              <div className="color-container" style={ { backgroundColor: '#1FD2D2' } }>
                <div className="top-left">#1FD2D2</div>
                <div className="top-right">R31 G210 B210</div>
                <div className="clearer"></div>
                <div className="bottom-right">C64 M00 Y25 K00</div>
              </div>
          </Col>
        </Row>
        <h3>Logo & Icons</h3>
        <Row>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/Pollfish_logo@2x.jpg" />
              <h5>Logo</h5>
              <div>
                <a href="/homeassets/mediakit/logo/Pollfish_logo.jpg">jpg</a> | <a href="/homeassets/mediakit/logo/Pollfish_logo.pdf">vector</a> | <a href="/homeassets/mediakit/logo/Pollfish_logo.png">transparent png</a>
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/Pollfish_logo_neg@2x.jpg" />
              <h5>Logo Negative</h5>
              <div>
                <a href="/homeassets/mediakit/logo-negative/Pollfish_logo_negative.jpg">jpg</a> | <a href="/homeassets/mediakit/logo-negative/Pollfish_logo_negative.pdf">vector</a> | <a href="/homeassets/mediakit/logo-negative/Pollfish_logo_negative.png">transparent png</a>
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/Pollfish_hor_@2x.jpg" />
              <h5>Logo Horizontal</h5>
              <div>
                <a href="/homeassets/mediakit/logo-horizontal/Pollfish_logo_H.jpg">jpg</a> | <a href="/homeassets/mediakit/logo-horizontal/Pollfish_logo_H.pdf">vector</a> | <a href="/homeassets/mediakit/logo-horizontal/Pollfish_logo_H.png">transparent png</a>
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/Pollfish_hor_neg@2x.jpg" />
              <h5>Logo Horizontal Negative</h5>
              <div>
                <a href="/homeassets/mediakit/logo-horizontal-negative/Pollfish_logo_H_negative.jpg">jpg</a> | <a href="/homeassets/mediakit/logo-horizontal-negative/Pollfish_logo_H_negative.pdf">vector</a> | <a href="/homeassets/mediakit/logo-horizontal-negative/Pollfish_logo_H_negative.png">transparent png</a>
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/icon@2x.jpg" />
              <h5>Icon</h5>
              <div>
                <a href="/homeassets/mediakit/icon/Pollfish_icon.jpg">jpg</a> | <a href="/homeassets/mediakit/icon/Pollfish_icon.pdf">vector</a> | <a href="/homeassets/mediakit/icon/Pollfish_icon.png">transparent png</a>
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className="logo_icon_wrapper">
              <img src="/homeassets/images/icon_neg@2x.jpg" />
              <h5>Icon Negative</h5>
              <div>
                <a href="/homeassets/mediakit/icon-negative/Pollfish_icon_negative.jpg">jpg</a> | <a href="/homeassets/mediakit/icon-negative/Pollfish_icon_negative.pdf">vector</a> | <a href="/homeassets/mediakit/icon-negative/Pollfish_icon_negative.png">transparent png</a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  },

  render() {
    return (
      <SinglePage
        title="MediaKit"
        content={ this.renderContent() } />
    );
  }

});

export default MediaKit;

