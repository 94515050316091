"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.__keep = exports.joinConcat = exports.joinStrict = exports.joinLeading = exports.all = exports.joinTrailing = exports.use = exports.nextTick = exports.setEventEmitter = exports.createActions = exports.createStore = exports.createAction = exports.utils = exports.StoreMethods = exports.PublisherMethods = exports.ListenerMethods = exports.ActionMethods = exports.version = undefined;

var _ActionMethods = require("./ActionMethods");

var ActionMethods = _interopRequireWildcard(_ActionMethods);

var _ListenerMethods = require("./ListenerMethods");

var ListenerMethods = _interopRequireWildcard(_ListenerMethods);

var _PublisherMethods = require("./PublisherMethods");

var PublisherMethods = _interopRequireWildcard(_PublisherMethods);

var _StoreMethods = require("./StoreMethods");

var StoreMethods = _interopRequireWildcard(_StoreMethods);

var _joins = require("./joins");

var _utils = require("./utils");

var _ = _interopRequireWildcard(_utils);

var _createAction = require("./createAction");

var _createStore = require("./createStore");

var _Keep = require("./Keep");

var __keep = _interopRequireWildcard(_Keep);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var version = {
    "reflux-core": "1.0.0"
};

var joinTrailing = (0, _joins.staticJoinCreator)("last");
var all = joinTrailing; // Reflux.all alias for backward compatibility
var joinLeading = (0, _joins.staticJoinCreator)("first");
var joinStrict = (0, _joins.staticJoinCreator)("strict");
var joinConcat = (0, _joins.staticJoinCreator)("all");

var utils = _;


/**
 * Convenience function for creating a set of actions
 *
 * @param definitions the definitions for the actions to be created
 * @returns an object with actions of corresponding action names
 */
var createActions = function () {
    var reducer = function reducer(definitions, actions) {
        Object.keys(definitions).forEach(function (actionName) {
            var val = definitions[actionName];
            actions[actionName] = (0, _createAction.createAction)(val);
        });
    };

    return function (definitions) {
        var actions = {};
        if (definitions instanceof Array) {
            definitions.forEach(function (val) {
                if (_.isObject(val)) {
                    reducer(val, actions);
                } else {
                    actions[val] = (0, _createAction.createAction)(val);
                }
            });
        } else {
            reducer(definitions, actions);
        }
        return actions;
    };
}();

/**
 * Sets the eventmitter that Reflux uses
 */
function setEventEmitter(ctx) {
    _.EventEmitter = ctx;
}

/**
 * Sets the method used for deferring actions and stores
 */
function nextTick(nextTick) {
    _.nextTick = nextTick;
}

function use(pluginCb) {
    pluginCb(this);
}

/**
 * Provides the set of created actions and stores for introspection
 */
/*eslint-disable no-underscore-dangle*/


// export in format that supports syntax: var Reflux = require('reflux-core');
exports.version = version;
exports.ActionMethods = ActionMethods;
exports.ListenerMethods = ListenerMethods;
exports.PublisherMethods = PublisherMethods;
exports.StoreMethods = StoreMethods;
exports.utils = utils;
exports.createAction = _createAction.createAction;
exports.createStore = _createStore.createStore;
exports.createActions = createActions;
exports.setEventEmitter = setEventEmitter;
exports.nextTick = nextTick;
exports.use = use;
exports.joinTrailing = joinTrailing;
exports.all = all;
exports.joinLeading = joinLeading;
exports.joinStrict = joinStrict;
exports.joinConcat = joinConcat;
exports.__keep = __keep;
/*eslint-enable no-underscore-dangle*/

// export in format that supports syntax: import Reflux from 'reflux-core';

Object.defineProperty(exports, "default", {
    get: function get() {
        return exports;
    }
});

/**
 * Warn if Function.prototype.bind not available
 */
if (!Function.prototype.bind) {
    console.error("Function.prototype.bind not available. " + "ES5 shim required. " + "https://github.com/spoike/refluxjs#es5");
}