import React from 'react';
import createReactClass from 'create-react-class';
import SinglePage from 'single_page';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Helmet from 'react-helmet';

const Testimonials = createReactClass({

  getMetaProps() {
    return {
      title: 'Testimonials · Pollfish',
      link: [
        { rel: 'canonical', href: 'https://www.pollfish.com/publisher/testimonials' }
      ],
      meta: [
        { name: 'description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' },
        { name: 'keywords', content: 'mobile apps, monetize, ads, mobile games, indie games, surveys, polls, mobile ad networks,ad sdk, survey sdk' },
        { property: 'og:title', content: 'Testimonials · Pollfish' },
        { property: 'og:image', content: 'https://www.pollfish.com/assets/images/logoFb.png' },
        { property: 'og:description', content: 'Pollfish is a mobile monetization network delivering surveys instead of mobile ads, paying up to 20x more than classic ad networks' }
      ]
    };
  },

  renderContent() {
    return (
      <div className="publisher-testimonials">
        <Helmet {...this.getMetaProps()} />
        <Row>
          <Col xs={4} md={2}>
            <img src="/homeassets/images/testimonials/howToDraw.png" />
          </Col>
          <Col xs={8} md={10}>
            <h3>How to Draw: Celebrities app</h3>
            <p>
              “Easy implementing. High rewarding. Fast responding.”
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            <img src="/homeassets/images/testimonials/quizoid.png" />
          </Col>
          <Col xs={8} md={10}>
            <h3>Quizoid</h3>
            <p>
              “Integrating Pollfish totally makes sense to us. Not just for the money, but also for the great idea of mobile survey distribution. A true 21st century business model!”
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            <img src="/homeassets/images/testimonials/ssms.png" />
          </Col>
          <Col xs={8} md={10}>
            <h3>SSMS App</h3>
            <p>
              “Pollfish was very useful for us. It allowed us to directly communicate with our users in a more convenient and quicker way through our app. We had a decent response rate that has helped us make decisions regarding improvements and new ideas.”
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={4} md={2}>
            <img src="/homeassets/images/testimonials/defragger.png" />
          </Col>
          <Col xs={8} md={10}>
            <h3>Defragger Pro</h3>
            <p>
              “The one line of code required by Pollfish has boosted my revenue by 250%.”
            </p>
          </Col>
        </Row>
      </div>
    );
  },

  render() {
    return (
      <SinglePage
        title="App Case Studies"
        content={ this.renderContent() } />
    );
  }

});

export default Testimonials;


