import _ from 'lodash';
import path from 'path';
import React from 'react';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import LeftNav from 'material-ui/Drawer';
import radio from 'radio';
import cookie from 'js-cookie';
import cx from 'classnames';
import LoginStore from '../../stores/login_store';
import createReactClass from 'create-react-class';
import URLSearchParams from 'url-search-params';

const menuItems = [
  { href: '/how-it-works', text: 'How It Works' },
  { href: '/#pricing', text: 'Pricing' },
  { href: '/blog', text: 'Blog' },
  { href: '/pf', text: 'Resources' },
  { to: '/contact', text: 'Contact' }
];

const Header = createReactClass({

  contextTypes: {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    screen: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired
  },

  mixins: [
    Reflux.listenTo(LoginStore, 'onDataChange')
  ],

  UNSAFE_componentWillMount() {
    radio('update:header').subscribe(this.onUpdateHeader);

    this.debouncedSetState = _.debounce((data) => {
      this.setState(data);
    });

    const query = new URLSearchParams(this.context.location.search);
    const offer = query.get('offer');

    if (offer) {
      cookie.set('offer', offer, { expires: 10 });
    }

    this.onUpdateHeader();
  },

  componentWillUnmount() {
    radio('update:header').unsubscribe(this.onUpdateHeader);
  },

  getInitialState() {
    return {
      title: '',
      theme: 'transparent',
      loggedIn: false,
      open: false,
      isDeveloper: false
    };
  },

  onDataChange(data) {
    const offer = cookie.get('offer');

    if (data.loggedIn) {
      this.setState({ isDeveloper: data.is_developer, loggedIn: true });
      if (offer) {
        LoginStore.sendOffer(offer, true);
      }
    }
  },

  onUpdateHeader(data) {
    if (data) {
      const nextState = _.clone(data);
      if (this.context.screen === 'mobile' || this.context.screen === 'tablet') {
        _.merge(nextState, { theme: 'bright' });
        if (nextState.title === '') {
          nextState.title = 'Survey Millions';
        }
      }
      if (!nextState.theme) {
        _.merge(nextState, { theme: 'transparent' });
      }

      this.setState(nextState);
    }
  },

  getLeftNavStyle() {
    const style = { };
    if (this.context.screen === 'mobile') {
      style.display = this.state.open ? '' : 'none';
    }

    return style;
  },

  getSidebarItems() {
    const type = this.getHeaderType();
    let items = menuItems;

    if (type === 'researcher') {
      items = _.reject(items, { to: '/docs' });
    }

    return _.map(items, (item, index) => {
      let content;
      const props = _.omit(item, 'text');
      if (item.href) {
        content = (
          <a {...props} key={`link-${index}`}>
            { item.text }
          </a>
        );
      } else {
        content = (
          <Link key={`link-${index}`} onClick={this.closeLeftNav} {...props}>
            { item.text }
          </Link>
        );
      }

      return content;
    });
  },

  getClasses() {
    return cx({
      'animated': true,
      'fadeInDown': this.state.logoHover,
      'fadeOutUp': !(!_.isUndefined(this.state.logoHover)
                      ? this.state.logoHover
                      : true),
      'icon-pollfish': true
    });
  },

  getHeaderType() {
    let className;
    const pathname = this.context.location.pathname;

    if (pathname === '/' || !!~pathname.search('researcher')) {
      className = 'researcher';
    } else if (!!~pathname.search('publisher')) {
      className = 'publisher';
    } else {
      className = '';
    }

    return className;
  },

  getStyles() {
    let background;
    let position = 'static';
    let referral = false;
    const pathname = this.context.location.pathname;
    const params = this.context.match.params;

    if (params) {
      referral = params.publisherReferral || params.researcherReferral || false;
    }

    if (pathname === '/' || pathname === '/publisher' || referral) {
      position = 'fixed';
    } else if (pathname === '/map') {
      background = '#1a1f27';
    } else {
      background = '#e44044';
    }

    return { position, background, backgroundSize: 'cover' };
  },

  getLoginLinks() {
    const type = this.getHeaderType();
    const loginPath = path.join('/login', type);
    const signupPath = path.join('/signup', type);
    let dashboardPath;

    if (this.state.isDeveloper) {
      dashboardPath = '/dashboard/dev/apps';
    } else {
      dashboardPath = '/dashboard/surveys';
    }

    return { loginPath, signupPath, dashboardPath };
  },

  renderButtons() {
    const pathname = this.context.location.pathname;
    const buttons = [];

    if ((pathname === '/' && this.state.title !== '') || pathname === '/map') {
      buttons.push({
        className: 'btn-1c create-survey btn btn-default',
        href: '/signup/researcher',
        content: 'Create a Survey',
        external: true
      });
    }

    if (pathname === '/publisher' && this.state.title !== '') {
      buttons.push({
        className: 'btn-1c sdk-docs btn btn-default',
        href: '/docs',
        content: 'SDK & Documentation',
        external: false
      });
    }

    if ((pathname === '/' || !!~pathname.search('/referral')) && this.state.title === '') {
      buttons.push({
        className: 'btn-1c publisher btn btn-default',
        href: '/publisher',
        content: 'Publisher\'s Area',
        external: true
      });
    }

    if (!!~pathname.search('/publisher') && this.state.title === '') {
      buttons.push({
        className: 'btn-1c researcher btn btn-default',
        href: '/',
        content: 'Researcher\'s Area',
        external: true
      });
    }

    if (this.context.screen !== 'mobile') {
      buttons.push(...this.renderLogin());
    }

    return (
      <Nav pullRight>
        <React.Fragment>
          {buttons.map((button, index) => {
            return this.renderHeaderLink(button, index);
          })}
        </React.Fragment>
        <NavItem className="menu-toggle" onClick={this.clickSidebar} eventKey={1} href="#">
          <i className="icon-menu"></i>
        </NavItem>
      </Nav>
    );
  },

  renderLogin() {
    let buttons;
    const { loginPath, signupPath, dashboardPath } = this.getLoginLinks();

    if (this.state.loggedIn) {
      buttons = [{
        className: 'btn-1c login btn btn-default',
        href: dashboardPath,
        type: 'login',
        content: 'Dashboard',
        external: true
      }];
    } else {

      buttons = [
        {
          className: 'btn-1c signup btn btn-default',
          href: signupPath,
          content: 'Sign Up Free',
          type: 'login',
          disableIcon: true,
          external: true
        },
        {
          className: 'btn-1c login btn btn-default',
          href: loginPath,
          content: 'Login',
          type: 'login',
          disableIcon: true,
          external: true
        }
      ];
    }

    return buttons;
  },

  renderHeaderLink(button, index) {
    let content;
    let icon;

    if (!button.disableIcon) {
      icon = <i className="icon-arrow"></i>;
    }

    if (button.href && !button.external) {
      content = (
        <NavItem key={`button-${index}`} href={button.href} onClick={ button.onClick } className={ button.className }>
          <span>{ button.content }</span>
          { icon }
        </NavItem>
      );
    } else {
      content = (
        <a href={button.href || '#'} onClick={ button.onClick } key={`button-${index}`} className={ button.className }>
        <span>{ button.content }</span>
        { icon }
        </a>
      );
    }

    return content;
  },

  renderNavbar() {
    return (
      <Navbar style={ this.getStyles() } className={this.state.theme} fixedTop fluid>
        { this.renderBrand() }
        { this.renderButtons() }
        <Nav>
          <NavItem className="main-title-nav">
            <h3 className="main-page-title">{this.state.title}</h3>
          </NavItem>
        </Nav>
      </Navbar>
    );
  },

  renderBrand() {
    return (
      <Navbar.Brand>
        <a className="logo" href="/">
          <i className="icon-logo"></i>
          <i className="icon-pollfish"></i>
        </a>
      </Navbar.Brand>
    );

  },

  renderHeaderButton() {
    const type = this.getHeaderType();
    const pathname = this.context.location.pathname;
    let buttons = [
      { type: 'publisher', content: 'Researcher\'s Area', href: '/' },
      { type: 'researcher', content: 'Publisher\'s Area', href: '/publisher' }
    ];

    if (pathname === '/' || pathname === '/publisher') {
      buttons = _.where(buttons, { type });
    }

    if (this.context.screen === 'mobile') {
      buttons.push(...this.renderLogin());
    }

    const buttonItems = _.map(buttons, (button, index) => {
      return (
        <Link onClick={this.closeLeftNav} key={`button-${index}`} to={button.href} className={`btn-1c ${button.type} btn btn-default`}>
          <span>{ button.content }</span>
          <i className="icon-arrow"></i>
        </Link>
      );
    });

    return (
      <div>
        <i className="icon-close close-btn" onClick={this.closeLeftNav}></i>
        <div className="header-buttons">
        { buttonItems }
        </div>
      </div>
    );
  },

  render() {
    return (
      <div id="main-header">
        { this.renderNavbar() }
        <LeftNav ref="leftNav"
          style={ this.getLeftNavStyle() }
          className="left-nav"
          open={this.state.open}
          onRequestChange={open => this.setState({ open })}
          docked={false}
          openSecondary={true}
          >
          <div className="menu">
          { this.renderHeaderButton() }
          { this.getSidebarItems() }
          </div>
        </LeftNav>
      </div>
    );
  },

  closeLeftNav() {
    this.setState({ open: false });
  },

  clickSidebar() {
    this.setState({ open: !this.state.open });
  },

  mouseOver() {
    if (this.context.screen !== 'mobile' ||
        this.context.screen !== 'tablet'
    ) {
      this.debouncedSetState({ logoHover: true });
    }
  },

  mouseOut() {
    if (this.context.screen !== 'mobile' ||
        this.context.screen !== 'tablet'
    ) {
      this.debouncedSetState({ logoHover: false });
    }
  }

});

export default Header;
