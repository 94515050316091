import Reflux from 'reflux';
import request from 'superagent';
import TermsActions from '../actions/terms_actions';

class TermsStore extends Reflux.Store {
  constructor() {
    super();
    this.listenables = TermsActions;
  }

  onFetch(filename) {
    const url = `https://api.github.com/repos/pollfish/docs/contents/terms/${filename}.md`;
    request.get(url)
      .set('Accept', 'application/vnd.github.VERSION.raw')
      .end((err, res) => {
        if (err) {
          return false;
        }
        const content = res.text;
        this.setState({ content, loading: false });
      });
  }
}

export default TermsStore;

