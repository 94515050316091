import Reflux from 'reflux';

const ResetActions = Reflux.createActions([
  'set',
  'resetPassword',
  'requestPassword'
]);

export default ResetActions;


